.block.block-product-slider {
  position: relative;
  padding: 0 2.5*$base-width 25*$base-width;
  margin-top: 12.5*$base-width;
  @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
    padding: 0 2.5*$base-width 12.5*$base-width;
    margin-top: 10*$base-width; }
  @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
    padding: 0*$base-width 2.5*$base-width; }
  @media only screen and (max-width: #{map-get($breakpoints, m)}) {
    padding: 0 2.5*$base-width; }

  .wrapper {
    max-width: calc(#{$boxed} * 1.1);
    margin: 0 auto;
    // padding-top: 10%
    position: relative;

    // overflow: hidden

    .owl-item {
      padding: 0 3*$base-width; }
    .product-teaser {
      display: block;

      &.productimage {
        padding-top: 270%;
        position: relative;
        transform: translateY(-9%) scale(.6);
        transition: all .3s ease-in-out;
        @media (max-width: #{map-get($breakpoints, l)}) {
          padding-top: 200%; }
        @media (max-width: 650px) {
          padding-top: 100%; }

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          object-fit: contain;
          object-position: center; }

        // Splash hover image
        &.productimagesplash {
          transition: all .1s cubic-bezier(0.18, 0.89, 0.32, 1.28);
          .splash {
            opacity: 0; }
          &.bottle-hover.violett {
            .img-hover {
              top: 35%; } }
          @media only screen and (min-width: #{map-get($breakpoints, s)}) {
            &:hover {
              transform: translateX(0) scale(1.088) !important;
              img {
                opacity: 0 !important;
                transition: opacity 50ms ease; }
              .splash {
                opacity: 1;
                img {
                  opacity: 1 !important;
                  transition: opacity 50ms ease; } } } } } }


      // &:hover
 }      //   transform: translateY(calc(-9% - #{3*$base-width})) scale(.6)

    .active:not(.center) {
      &.fourth {
        .productimage {
          transform: translateY(-9%) translateX(calc(-48% - #{3*$base-width})) scale(.6);
          // &:hover
 }          //   transform: translateY(calc(-9% - #{3*$base-width})) translateX(calc(-48% - #{3*$base-width})) scale(.6)

        &.left {
          .productimage {
            transform: translateY(-9%) translateX(calc(48% + #{3*$base-width})) scale(.6);
            // &:hover
 } } } }            //   transform: translateY(calc(-9% - #{3*$base-width})) translateX(calc(48% + #{3*$base-width})) scale(.6)

    .second {
      .product-teaser {
        &.productimage {
          // transform: translateY(0) translateX(0) scale(.82)
          transform: translateY(0) translateX(-6%) scale(.82);
          opacity: 0.6;
          @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            transform: translateY(0) translateX(-20%) scale(.82); } }

        // &:hover
        //   // transform: translateY(#{-4*$base-width}) translateX(0) scale(.82)
        //   transform: translateY(#{-4*$base-width}) translateX(-6%) scale(.82)

        &.productimagesplash {
          &:hover {
            transform: translateY(-0.5%) translateX(-6%) scale(0.889) !important;
            transition: all .1s cubic-bezier(0.18, 0.89, 0.32, 1.28); } } }

      &.left {
        .product-teaser {
          &.productimage {
            transform: translateY(0) translateX(6%) scale(.82);
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
              transform: translateY(0) translateX(20%) scale(.82); } }

          // &:hover
          //   transform: translateY(#{-4*$base-width}) translateX(6%) scale(.82)

          &.productimagesplash {
            &:hover {
              transform: translateY(-0.5%) translateX(6%) scale(0.889) !important;
              transition: all .1s cubic-bezier(0.18, 0.89, 0.32, 1.28); } } } } }

    .third {
      .product-teaser {
        &.productimage {
          // transform: translateY(-4.5%) scale(.7)
          transform: translateY(-4.5%) translateX(calc(-13.5% - #{3*$base-width})) scale(.7);
          opacity: 0.4; }

        // &:hover
        //   // transform: translateY(calc(-4.5% - #{2*$base-width})) scale(.7)
        //   transform: translateY(calc(-4.5% - #{2*$base-width})) translateX(calc(-13.5% - #{3*$base-width})) scale(.7)


        &.productimagesplash {
          &:hover {
            transform: translateY(-4.5%) translateX(calc(-13.5% - 2.4rem)) scale(0.75) !important;
            transition: all .1s cubic-bezier(0.18, 0.89, 0.32, 1.28); } } }

      &.left {
        .product-teaser {
          &.productimage {
            transform: translateY(-4.5%) translateX(calc(13.5% + #{3*$base-width})) scale(.7); }

          // &:hover
          //   transform: translateY(calc(-4.5% - #{2*$base-width})) translateX(calc(13.5% + #{3*$base-width})) scale(.7)

          &.productimagesplash {
            &:hover {
              transform: translateY(-4.5%) translateX(calc(13.5% + 2.4rem)) scale(0.75) !important;
              transition: all .1s cubic-bezier(0.18, 0.89, 0.32, 1.28); } } } } }

    .fourth {
      .product-teaser {
        &.productimage {
          opacity: 0.2; }

        &.productimagesplash {
          &:hover {
            transform: translateY(-9%) translateX(calc(-48% - 2.4rem)) scale(0.66) !important;
            transition: all .1s cubic-bezier(0.18, 0.89, 0.32, 1.28); } } }

      &.left {
        .product-teaser {
          &.productimagesplash {
            &:hover {
              transform: translateY(-9%) translateX(calc(48% + 2.4rem)) scale(0.66) !important;
              transition: all .1s cubic-bezier(0.18, 0.89, 0.32, 1.28); } } } } }

    .center {
      .product-teaser {
        &.productimage {
          transform: translateX(0) scale(1);
          .shadow {
            width: 65%;
            height: 100%;
            opacity: .4;
            filter: blur(50px);
            position: absolute;
            top: 0.5%;
            bottom: 0px;
            transform: scale(1.05);
            left: 15%;
            z-index: -1; // keep the .light under the .image
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
              width: 50%;
              height: 75%; }
            @media (max-width: 650px) {
              width: 70%;
              height: 50%;
              left: 15%;
              top: 20%;
              transform: scale(1); } } }



        // &:hover
 } }        //   transform: translateY(#{-4*$base-width}) translateX(0) scale(1)

    .bottle-hover {
      .img-hover {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 53px;
        height: 53px;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        opacity: 0;
        transition: transform .4s ease-in-out .4s;
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
          display: none; }


        .circle {
          width: 43px;
          height: 43px;
          border-radius: 100%;

          &:before, &:after {
            content: '';
            display: block;
            background: $light;
            background: $light; }

          &:before {
            width: 0.2rem;
            height: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); }

          &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 0.2rem; } } }

      &:hover .img-hover {
        border: 2px solid $black;
        opacity: 1;

        .circle {
          background-color: $black; } }

      &.yellow {
        .img-hover {
          border: 2px solid $yellow;

          .circle {
            background-color: $yellow; } } }

      &.green {
        .img-hover {
          border: 2px solid $green;

          .circle {
            background-color: $green; } } }

      &.blue {
        .img-hover {
          border: 2px solid $blue;

          .circle {
            background-color: $blue; } } }

      &.violett {
        .img-hover {
          border: 2px solid $berry;

          .circle {
            background-color: $berry; } } }

      &.red {
        .img-hover {
          border: 2px solid $red;

          .circle {
            background-color: $red; } } }

      &.orange {
        .img-hover {
          border: 2px solid $orange;

          .circle {
            background-color: $orange; } } }

      &.black {
        .img-hover {
          border: 2px solid $black;

          .circle {
            background-color: $black; } } } } }


  .owl-carousel {
    padding-top: 10%;
    z-index: 0;
    overflow: hidden;
    margin-top: -10%;
    @media (max-width: #{map-get($breakpoints, m)}) {
      padding-top: 25%;
      margin-top: -25%; }
    @media (max-width: #{map-get($breakpoints, s)}) {
      padding-top: 5%; }


    &:not(.owl-loaded) {
      display: block;
      opacity: 0; }

    &.owl-loaded {
      opacity: 1; }

    .owl-stage-outer {
      overflow: visible;
      z-index: 1;

      .owl-stage {
        z-index: 2; } } }

  #nav-custom {
    display: flex;
    justify-content: space-between;
    max-width: 1732px;
    z-index: 99;

    button {
      display: flex;
      align-items: center;
      position: absolute;
      top: 45%;
      background-color: transparent;
      overflow: hidden;
      cursor: pointer;
      border: none;
      border-radius: 50%;
      z-index: 10;

      &.owl-prev {
        left: 0;
        .owl-carousel-arrow {
          padding-right: 2px; }
        &:hover {
          .owl-carousel-arrow {
            transition: $transition;
            border: 2px solid $hover;
            background-color: $hover;
            box-shadow: 0 0 15px $hover; } } }


      &.owl-next {
        right: 0;
        .owl-carousel-arrow {
          padding-left: 2px; }
        &:hover {
          .owl-carousel-arrow {
            transition: $transition;
            border: 2px solid $hover;
            background-color: $hover; } } }


      &:hover {
        transition: $transition;
        box-shadow: 0 0 15px $hover; } }

    .owl-carousel-arrow {
      background-color: transparent;
      display: flex;
      width: 70px;
      height: 70px;
      justify-content: center;
      align-items: center;
      background: transparent;
      border-radius: 50%;
      border: 2px solid $light;
      @media (max-width: #{map-get($breakpoints, xl)}) {
        width: 50px;
        height: 50px; }

      img {
        width: 10px;
        height: auto;
        transition: $transition;
        background-color: transparent;
        border-radius: 50%; }

      &.owl-next {
        margin-left: 10px; } } } }
