.block.block-auto-instagram-fetch {
    padding: 0 2.5*$base-width;
    .wrapper {
        max-width: 1790px;
        margin: 0 auto;
        position: relative;
        .hl-wrapper {
            .headline-wrapper {
                max-width: 900px;
                margin: 0 auto;
                text-align: center;
                padding-bottom: 6.25*$base-width;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    padding-bottom: 0; }
                h2, .h2 {
                    text-transform: uppercase;
                    line-height: 0.8;
                    margin-bottom: 0;
                    font-size: 11rem;
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        font-size: 8rem;
                        margin-bottom: 4.375 * $base-width; }
                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                        font-size: 6rem;
                        margin-bottom: 3.75 * $base-width; } } }
            .social {
                display: flex;
                justify-content: center;
                align-items: center;
                .item {
                    padding: 0 10px;
                    &:hover {
                        svg {
                            transition: $transition;
                            box-shadow: 0 0 15px $hover;
                            path {
                                fill: $hover;
                                transition: $transition; } } } } } }

        .insta-grid {
            display: flex;
            justify-content: space-between;
            padding-top: 10*$base-width;
            @media only screen and (max-width: 1790px) {
                justify-content: space-around; }
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                flex-direction: column; }
            .instawrapper-first {
                width: 100%;
                max-width: 1228px;
                overflow: hidden;
                @media only screen and (max-width: 1790px) {
                    max-width: 778px; }
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    max-width: 100%;
                    padding-bottom: 5*$base-width; }

                figure {
                    display: flex;
                    max-height: 780px;
                    @media only screen and (max-width: 1790px) {
                        flex-direction: column;
                        max-height: 100%; }
                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                        flex-direction: unset;
                        justify-content: center;
                        max-height: 500px; }
                    @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                        flex-direction: column;
                        max-height: 100%; }

                    .videoholder {
                        max-width: 778px;
                        width: 100%;
                        max-height: 778px;
                        overflow: hidden;
                        @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                            max-width: 100%;
                            max-height: 500px; }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            max-height: 400px; }
                        video {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: 50% 50%;
                            @media only screen and (max-width: 1790px) {
                                object-position: 0 -330px; }
                            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                                object-position: 50% 50%; }
                            @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                                object-position: 0 -415px; }
                            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                object-position: 0 -330px; }
                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                object-position: 0 -250px; }
                            @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                                object-position: 0 -50px; } } }
                    figcaption {
                        display: -webkit-box;
                        -webkit-line-clamp: 15;
                        -webkit-box-orient: vertical;
                        overflow: hidden; }
                    a {
                        display: inline-block;
                        img {
                            max-width: 778px;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                                max-width: 100%;
                                max-height: 500px; }
                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                max-height: 400px; } } }
                    .textwrapper {
                        background-color: #FBFBFB;
                        max-width: 450px;
                        padding: 5*$base-width 6.25*$base-width;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        @media only screen and (max-width: 1790px) {
                            max-width: 100%; }
                        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                            max-width: 450px; }
                        @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                            max-width: 100%; }
                        figcaption {
                            color: $dark; } } } }

            .instagram-slider {
                width: 100%;
                max-width: 500px;
                position: relative;

                figure {
                    max-height: 778px;
                    height: 100%; }

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    max-width: 1228px;
                    margin: 0 auto;
                    .slider {
                        display: flex;
                        justify-content: center; } }
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    .slider {
                        display: block; } }
                .img-wrapper {
                    width: 500px;
                    height: 500px;
                    overflow: hidden;
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        width: 100%; }
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        max-height: 400px;
                        height: 100%; }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover; } }
                .textwrapper {
                    padding: 5*$base-width 6.25*$base-width;
                    background-color: #FBFBFB;
                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                        max-width: 400px; }
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        max-width: 100%; }
                    figcaption {
                        color: $dark;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical; } }


                .owl-nav {
                    display: flex;
                    z-index: 10;
                    button {
                        position: absolute;
                        top: 15%;
                        width: 70px;
                        height: 70px;
                        display: flex;
                        align-items: center;
                        transition: $transition;
                        border-radius: 50%;
                        background-color: $light;
                        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                            top: 40%; }
                        @media (max-width: #{map-get($breakpoints, l)}) {
                            width: 50px;
                            height: 50px;
                            margin: 0 10px; }
                        .owl-carousel-arrow {
                            background-color: transparent;
                            display: flex;
                            width: 100%;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                            svg {
                                max-width: 8px;
                                height: auto;
                                transition: $transition; } }
                        &:hover {
                            transition: $transition;
                            background-color: $hover;
                            box-shadow: 0 0 15px $hover;
                            .owl-carousel-arrow {
                                transition: $transition;
                                filter: invert(100%);
                                -webkit-filter: invert(100%); } }

                        &.owl-prev {
                            left: -7%;
                            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                                left: 10%; }
                            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                left: 0%; }
                            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                left: -3%; }
                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                left: -6%; } }
                        &.owl-next {
                            right: -7%;
                            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                                right: 10%; }
                            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                right: 0%; }
                            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                right: -3%; }
                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                right: -6%; } } } } }

            .btn.btn-primary {
                color: $hover;
                width: fit-content;
                display: flex;
                border: 2px solid $hover;
                align-items: center;
                justify-content: center;
                margin-top: 5*$base-width;
                svg {
                    margin-right: 10px;
                    transition: $transition; }
                &:hover {
                    transition: $transition;
                    color: $light;
                    svg {
                        transition: $transition;
                        path {
                            transition: $transition;
                            fill: $light;
                            stroke: $light; } } } } } } }

