.block-hero {
  max-width: $max;
  overflow: hidden;
  padding-bottom: 25*$base-width;
  margin: 0 auto;
  @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
    padding-bottom: 18.75*$base-width;
    margin-top: 12*$base-width; }
  @media only screen and (max-width: #{map-get($breakpoints, l)}) {
    padding-bottom: 12.5*$base-width; }

  .heroinner {
    // height: 85vh
    // height: 0
    // padding-bottom: 56.25%
    position: relative;
    overflow: hidden;
    // @media only screen and (max-width: 1920px)
    //   height: 90vh
    // @media only screen and (max-width: #{map-get($breakpoints, s)})
    //   padding-bottom: 100%
    .video-wrapper {
      overflow: hidden;
      z-index: -1;
      position: relative;
      padding-bottom: 50%;
      height: 0;
      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-bottom: 70%; }

      video {
        position: absolute;
        // left: 50%
        // top: 50%
        // transform: translate(-50%,-50%)
        max-width: auto;
        object-fit: cover;
        width: 100%;
        height: 100%;
        margin: 0;
        z-index: -2;
        top: 0;
        left: 0; } }

    .headline-wrapper {
      z-index: 3;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      max-width: max-content;
      max-height: fit-content;
      opacity: 0;
      @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-top: 20px; }
      .preline {
        font-size: 12rem;
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
          font-size: 10rem; }
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          font-size: 6rem;
          padding-bottom: 0; }
        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
          font-size: 4rem; } }
      h1 {
        padding-left: 26.25*$base-width;
        text-shadow: unset;
        font-size: 12rem;
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
          font-size: 10rem;
          margin-bottom: 4.375 * $base-width;
          padding-left: 21.25 *$base-width; }
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          padding-left: 12.5 * $base-width;
          font-size: 8rem; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          font-size: 6.5rem;
          margin-bottom: 3.75 * $base-width; }
        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
          font-size: 4rem; }

        strong {
          text-shadow: 3px 0 7px $yellow;
          color: $yellow;
          @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            text-shadow: unset; } } } }

    .srolldown {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 2px solid $light;
      border-radius: 50%;
      position: absolute;
      bottom: 30px;
      z-index: 3;
      left: 50%;
      transform: translateX(-50%);
      .arrow {
        padding-top: 2px;
        svg {
          max-width: 10px;
          fill: #fff;
          width: 100%; } }
      &:hover {
        .arrow {
          &:before {
            content: "";
            position: absolute;
            bottom: 42%;
            left: 48%;
            height: 35px;
            width: 2px;
            background-color: #fff;
            transition: $transition; } } }

      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        display: none; } }


    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0, 0.4);
      z-index: 2; } }

  // Animation
  &.in-view {
    .headline-wrapper {
      animation: sparkle-in-content 4s ease forwards;
      animation-delay: 2s; }

    @keyframes sparkle-in-content {
      0% {
        opacity: 0; }
      50% {
        opacity: 0.5; }
      100% {
        opacity: 1; } } } }
