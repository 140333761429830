.block-opener {
    .wrapper {
        max-width: $boxed;
        margin: 0 auto;
        padding: 0 2.5*$base-width;
        padding-top: 30*$base-width;
        position: relative;
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding-top: 22.5*$base-width; }
        .headline-wrapper {
            &.align-center {
                max-width: 900px;
                margin: 0 auto;
                text-align: center;
                padding-bottom: 12.5*$base-width;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    padding-bottom: 6.25*$base-width; } }
            h1, .h1 {
                text-transform: uppercase;
                line-height: 0.8;
                margin-bottom: 0;
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    line-height: 1; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    text-shadow: unset; } } }

        .text-wrapper {
            max-width: 900px;
            margin: 0 auto;
            text-align: center;
            padding-bottom: 12.5*$base-width; } } }
