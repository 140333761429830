.block-history {
    .wrapper {
        max-width: $boxed;
        margin: 0 auto;
        padding: 0 2.5*$base-width;
        .hero {
            padding-top: 30*$base-width;
            position: relative;
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding-top: 22.5*$base-width; }
            .headline-wrapper {
                max-width: 900px;
                margin: 0 auto;
                text-align: center;
                padding-bottom: 12.5*$base-width;
                h1, .h1 {
                    text-transform: uppercase;
                    line-height: 0.8;
                    margin-bottom: 0;
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        line-height: 1; }
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        text-shadow: unset; } } }

            .media {
                z-index: 2;
                min-height: 100%;
                max-width: 1640px;
                width: 100%;
                padding-top: 65%;
                position: relative;
                margin: 0 auto;

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    padding-top: 56.25%;
                    flex: 0 0 100%;
                    width: 100%; }
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    padding-top: 65%; }
                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center; } } }

        .steps-wrapper {
            position: relative;
            padding-top: 18.75*$base-width;
            margin-top: 18.75*$base-width;
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                padding-top: 0; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                margin-top: 12.5*$base-width; }
            .headline-wrapper {
                position: absolute;
                top: 180px;
                max-width: 674px;
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    position: relative;
                    top: unset; }

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    margin: auto; } }

            .step-item {
                position: relative;
                padding-bottom: 25*$base-width;
                display: flex;
                flex-wrap: wrap;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    justify-content: center;
                    padding-bottom: 37.5*$base-width; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    flex-wrap: wrap;
                    padding-bottom: 37.5*$base-width; }

                &:nth-child(even) {
                    justify-content: flex-end;
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        justify-content: center; } }

                .step-inner {
                    max-width: 674px;
                    width: 100%;
                    opacity: 0;

                    p {
                        padding-bottom: 0; } }

                .year-image {
                    // display: flex
                    // align-items: flex-end
                    position: relative;
                    .year {
                        font-family: $headline-font;
                        font-size: 15rem;
                        line-height: 1;
                        display: block;
                        color: $light;
                        text-shadow: 3px 0 7px $yellow;
                        padding-bottom: 5*$base-width;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            font-size: 10rem; }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            font-size: 6.5rem; } }

                    &:after {
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: -49%;
                        transform: translate(0, 49%);
                        left: 0;
                        z-index: -1;
                        background-image: linear-gradient(to right, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
                        background-size: 17px 5px;
                        background-repeat: repeat-x; }

                    .image {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        img {
                            filter: grayscale(1);
                            // max-height: 420px
 } } }                            // max-width: 380px

                .subline {
                    padding-top: 5 * $base-width; }


                &.project-step-1 {
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        padding-top: 31.25*$base-width; }
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        padding-top: 20*$base-width; }
                    &:before {
                        content: " ";
                        background-image: url('/wp-content/themes/bergauf/assets/img/layout/history/first-step.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        position: absolute;
                        left: -8%;
                        right: 0;
                        top: -82%;
                        margin: 0 auto;
                        max-width: 495px;
                        width: 100%;
                        max-height: 57rem;
                        height: 100%;
                        z-index: -1;
                        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                            max-width: 360px; }
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            content: none; } }


                    .image {
                        max-width: 280px;
                        width: 100%;
                        right: 6rem;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 220px; }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            max-width: 180px;
                            right: 0; }
                        img {
                            width: 100%; } }

                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        &:after {
                            content: '';
                            background-image: url('/wp-content/themes/bergauf/assets/img/layout/history/first-step.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            position: absolute;
                            width: 100%;
                            max-height: 20rem;
                            bottom: 8%;
                            height: 100%;
                            z-index: -1;
                            max-width: 20rem; } } }


                &.project-step-2 {
                    &:before {
                        content: " ";
                        background-image: url('/wp-content/themes/bergauf/assets/img/layout/history/second-step.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        background-size: contain;
                        left: 16%;
                        top: -65%;
                        margin: 0 auto;
                        max-width: 665px;
                        width: 100%;
                        max-height: 36.7rem;
                        height: 100%;
                        z-index: -1;
                        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                            max-width: 590px; }
                        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                            max-width: 500px;
                            left: 9%;
                            top: -58%; }
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            content: none; } }

                    .image {
                        right: 19rem;
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            right: 0; }
                        img {
                            max-width: 235px;
                            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                max-width: 180px; }
                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                max-width: 100px; } } }

                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        &:after {
                            content: '';
                            background-image: url('/wp-content/themes/bergauf/assets/img/layout/history/fifth-step.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            position: absolute;
                            width: 100%;
                            max-height: 20rem;
                            bottom: 8%;
                            height: 100%;
                            z-index: -1;
                            max-width: 20rem; } } }


                &.project-step-3 {
                    &:before {
                        content: " ";
                        background-image: url('/wp-content/themes/bergauf/assets/img/layout/history/third-step.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        background-size: contain;
                        left: 6%;
                        right: 0;
                        top: -45%;
                        margin: 0 auto;
                        max-width: 356px;
                        width: 100%;
                        max-height: 27.6rem;
                        height: 100%;
                        z-index: -1;
                        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                            max-width: 280px; }
                        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                            max-width: 260px;
                            left: 25%; }
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            content: none; } }

                    .image {
                        max-width: 357px;
                        width: 100%;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 300px; }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            max-width: 200px; }

                        img {
                            width: 100%; } }

                    &:after {
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            content: '';
                            background-image: url('/wp-content/themes/bergauf/assets/img/layout/history/first-step.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            position: absolute;
                            width: 100%;
                            max-height: 20rem;
                            bottom: 8%;
                            height: 100%;
                            z-index: -1;
                            max-width: 20rem; }

                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            left: 10%; } } }


                &.project-step-4 {
                    padding-bottom: 45 * $base-width;
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        padding-bottom: 37.5*$base-width; }
                    &:before {
                        content: " ";
                        background-image: url('/wp-content/themes/bergauf/assets/img/layout/history/fourth-step.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        background-size: cover;
                        left: -30%;
                        right: 0;
                        top: -55%;
                        margin: 0 auto;
                        max-width: 70rem;
                        width: 100%;
                        max-height: 485px;
                        height: 100%;
                        z-index: -1;
                        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                            max-width: 58rem; }
                        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                            max-width: 55rem;
                            max-height: 420px;
                            top: -45%;
                            left: -50%; }
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            content: none; } }

                    .image {
                        max-width: 368px;
                        width: 100%;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 300px; }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            max-width: 200px; }
                        img {
                            width: 100%; } }

                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        &:after {
                            content: '';
                            background-image: url('/wp-content/themes/bergauf/assets/img/layout/history/fifth-step.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            position: absolute;
                            width: 100%;
                            max-height: 20rem;
                            bottom: 8%;
                            height: 100%;
                            z-index: -1;
                            max-width: 20rem; } } }

                &.project-step-5 {
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        padding-bottom: 31.25*$base-width; }
                    &:before {
                        content: " ";
                        background-image: url('/wp-content/themes/bergauf/assets/img/layout/history/fifth-step.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        background-size: contain;
                        left: 15%;
                        right: 0;
                        top: -85%;
                        margin: 0 auto;
                        max-width: 41rem;
                        width: 100%;
                        max-height: 40rem;
                        height: 100%;
                        z-index: -1;
                        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                            max-width: 34rem; }
                        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                            max-width: 30rem;
                            left: 34%;
                            top: -82%; }
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            content: none; } }

                    .image {
                        max-width: 346px;
                        width: 100%;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 300px; }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            max-width: 200px; }
                        img {
                            width: 100%; } }

                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        &:after {
                            content: '';
                            background-image: url('/wp-content/themes/bergauf/assets/img/layout/history/first-step.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            position: absolute;
                            width: 100%;
                            max-height: 20rem;
                            bottom: 8%;
                            height: 100%;
                            z-index: -1;
                            max-width: 20rem; } } }

                &.project-step-6 {
                    padding-bottom: 6.25 * $base-width;
                    &:before {
                        content: " ";
                        background-image: url('/wp-content/themes/bergauf/assets/img/layout/history/sixth-step.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        background-size: contain;
                        left: -15%;
                        right: 0;
                        top: -58%;
                        margin: 0 auto;
                        max-width: 44rem;
                        width: 100%;
                        max-height: 20rem;
                        height: 100%;
                        z-index: -1;
                        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                            max-width: 40rem; }
                        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                            max-width: 35rem;
                            top: -60%;
                            left: -40%; }
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            content: none; } }

                    .image {
                        max-width: 275px;
                        width: 100%;
                        right: 6rem;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 300px; }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            max-width: 200px;
                            right: 0; }
                        img {
                            width: 100%;
                            filter: unset; } } } } } }



    .in-view {
        &.project-step-1 {
            .step-inner {
                animation: 400ms slide-in-right ease forwards; } }
        &.project-step-2 {
            .step-inner {
                animation: 400ms slide-in-left ease forwards; } }
        &.project-step-3 {
            .step-inner {
                animation: 400ms slide-in-right ease forwards; } }
        &.project-step-4 {
            .step-inner {
                animation: 400ms slide-in-left ease forwards; } }
        &.project-step-5 {
            .step-inner {
                animation: 400ms slide-in-right ease forwards; } }
        &.project-step-6 {
            .step-inner {
                animation: 400ms slide-in-left ease forwards; } }


        @keyframes slide-in-left {
            from {
                margin-left: -25%;
                margin-right: 25%;
                opacity: 0; }
            to {
                margin-left: 0;
                margin-right: 0;
                opacity: 1; } }

        @keyframes slide-in-right {
            from {
                margin-left: 25%;
                margin-right: -25%;
                opacity: 0; }
            to {
                margin-left: 0;
                margin-right: 0;
                opacity: 1; } } } }
