.block-teaser {
    padding: 0 0 12.5*$base-width;
    max-width: $boxed;
    margin: 0 auto;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        padding-bottom: 0; }

    .teaser-wrapper {
        z-index: 2;
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        a {
            text-decoration: none; }
        .teaseritem {
            position: relative;
            flex: 0 0 50%;
            width: 50%;
            padding: 2.5*$base-width;
            opacity: 0;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding: 1.25*$base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%;
                width: 100%;
                padding: 1.25*$base-width 0; }
            .teaser {
                position: relative;
                display: flex;
                align-items: center;
                height: 510px;
                overflow: hidden;
                @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                    height: 450px; }
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    height: 380px; }
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    height: 350px; }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: -2;
                    width: 100%;
                    object-fit: cover;
                    height: 100%; }
                .text {
                    width: 65%;
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    flex-direction: column;
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        width: 70%; }
                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                        width: 85%; }

                    .preline {
                        color: $light; }

                    .btn {
                        color: #fff;
                        display: inline-block;
                        border: 2px solid #fff;
                        background-color: unset;
                        max-width: fit-content;
                        margin-top: 40px; } }

                img {
                    transition: $transition;
                    opacity: 0.44; }

                picture {
                    &::before {
                        content: '';
                        opacity: 1;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                        background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0.8) 35%, rgba(0, 0, 0, 0) 100%); } }
                &:hover {
                    .btn {
                        transition: $transition;
                        background-color: $hover;
                        border: 2px solid $hover;
                        box-shadow: 0 0 15px $hover; }
                    img {
                        transform: scale(1.1); } }

                .headline-wrapper {
                    h2 {
                        margin-bottom: 0; } } } }

        //ANIMATION
        &.in-view {
            .teaseritem:nth-child(1) {
                animation: slide-in-teaser 200ms ease-in-out forwards;
                animation-delay: .35s; }

            .teaseritem:nth-child(2) {
                animation: slide-in-teaser 200ms ease-in-out forwards;
                animation-delay: .55s; }


            .teaseritem:nth-child(3) {
                animation: slide-in-teaser 200ms ease-in-out forwards;
                animation-delay: .75s; }


            .teaseritem:nth-child(4) {
                animation: slide-in-teaser 200ms ease-in-out forwards;
                animation-delay: .95s; }


            .teaseritem:nth-child(5) {
                animation: slide-in-teaser 200ms ease-in-out forwards;
                animation-delay: 1.15s; }


            .teaseritem:nth-child(6) {
                animation: slide-in-teaser 200ms ease-in-out forwards;
                animation-delay: 1.35s; }

            @keyframes slide-in-teaser {
                0% {
                    transform: translateX(-50%);
                    opacity: 0; }

                50% {
                    transform: translateX(-25%);
                    opacity: 0.5; }

                100% {
                    transform: translateX(0);
                    opacity: 1; } } } } }
