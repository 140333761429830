.block-drink-facts {
    padding: 0 2.5* $base-width;
    margin-top: 22.5 *$base-width;
    position: relative;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        margin-top: 10*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        margin-top: 6.25*$base-width; }
    .headline-wrapper {
        text-align: center;
        margin-bottom: 18.75 *$base-width;
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            margin-bottom: 15*$base-width; }
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            margin-bottom: 10*$base-width; } }
    .drinkfacts {
        position: relative;
        padding-bottom: 6.25 *$base-width;
        .wrapper {
            max-width: $boxed;
            margin: 0 auto;
            height: 100%;
            position: relative;
            @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                display: flex;
                align-items: center; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                flex-direction: column; } }
        .productimage {
            position: relative;
            margin: 0 auto;
            max-width: 330px;
            @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                max-width: 250px; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                max-width: 180px; }
            .picture {
                img {
                    width: 100%; } }
            &:after {
                //content: ''
                content: none;
                background-image: url(/wp-content/themes/bergauf/assets/img/layout/arrow.svg);
                top: 60%;
                right: -50%;
                transform: translate(25%, -35%);
                max-width: 130px;
                width: 100%;
                max-height: 105px;
                height: 100%;
                position: absolute;
                background-repeat: no-repeat;
                @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                    display: none; } } }
        .text-wrapper {
            @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                flex: 0 0 60%; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                padding-top: 10*$base-width; }
            .fact {
                position: absolute;
                opacity: 0;
                @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                    position: relative;
                    padding-left: 5*$base-width;
                    &::before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        max-width: 25px;
                        position: absolute;
                        left: 0;
                        z-index: -1;
                        background-image: url(/wp-content/themes/bergauf/assets/img/layout/check.svg);
                        background-repeat: no-repeat; } }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    padding-left: 3rem;
                    &::before {
                        max-width: 20px;
                        top: 5px; } }


                p {
                    padding-bottom: 10px;
                    @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                        padding-bottom: 15px; } }

                &.fact-1 {
                    top: 5rem;
                    max-width: 450px;
                    text-align: right;
                    left: 22rem;
                    &:after {
                        width: 500px;
                        left: 65px; }

                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        left: 16rem;
                        max-width: 420px; }
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        left: 5rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                        max-width: 100%;
                        text-align: left;
                        left: unset;
                        top: unset; } }

                &.fact-2 {
                    top: 15rem;
                    right: 34rem;
                    &:after {
                        width: 415px;
                        right: 0; }
                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        right: 25rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        right: 15rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                        right: unset;
                        top: unset; } }

                &.fact-3 {
                    top: 25rem;
                    left: 45rem;
                    &:after {
                        width: 400px;
                        left: 0; }
                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        left: 35rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        left: 20rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                        left: unset;
                        top: unset; } }



                &.fact-4 {
                    top: 31rem;
                    right: 10rem;
                    &:after {
                        width: 630px;
                        right: 0; }
                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        right: 5rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        right: 0rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                        right: unset;
                        top: unset; } }

                &.fact-5 {
                    max-width: 350px;
                    text-align: right;
                    top: 40rem;
                    left: 22rem;
                    &:after {
                        width: 500px;
                        left: 45px; }
                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        left: 15rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        left: 0rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                        max-width: 100%;
                        text-align: left;
                        left: unset;
                        top: unset; } }


                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    bottom: -50%;
                    transform: translate(0, 50%);
                    z-index: -1;
                    background-image: linear-gradient(to right, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
                    background-size: 20px 1px;
                    background-repeat: repeat-x;
                    @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                        display: none; } } }



            .cta {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 47rem;
                @media only screen and (max-width: 1480px) {
                    width: 35rem; }
                @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                    position: relative;
                    padding-top: 5*$base-width; }
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    width: 100%;
                    text-align: center;
                    padding-top: 10*$base-width; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    padding-top: 7.5*$base-width; } } }



        .graphic {
            position: absolute;
            z-index: -1;
            svg {
                max-width: 350px;
                width: 100%;

                @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                    max-width: 280px;
                    max-height: 280px; }
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    max-width: 200px;
                    max-height: 250px; }
                @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                    max-width: 150px;
                    max-height: 150px; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    max-width: 130px;
                    max-height: 130px; } }
            &.top-right {
                right: -4.5rem;
                top: -17rem;
                transform: rotate(25deg);
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    top: -10rem; } }
            &.top-left {
                top: -9rem;
                left: -3rem; }
            &.bottom-left {
                bottom: -6rem;
                left: -6rem;
                transform: rotate(11deg); }
            &.bottom-right {
                bottom: -6rem;
                right: -6rem; } }

        &.in-view {
            .fact-1 {
                animation: 250ms slide-in-left ease forwards;
                animation-delay: .75s; }
            .fact-2 {
                animation: 250ms slide-in-right ease forwards;
                animation-delay: .95s; }
            .fact-3 {
                animation: 250ms slide-in-left ease forwards;
                animation-delay: 1.15s; }
            .fact-4 {
                animation: 250ms slide-in-right ease forwards;
                animation-delay: 1.35s; }
            .fact-5 {
                animation: 250ms slide-in-left ease forwards;
                animation-delay: 1.55s; }

            @keyframes slide-in-left {
                from {
                    margin-left: -25%;
                    margin-right: 25%;
                    opacity: 0; }
                to {
                    margin-left: 0;
                    margin-right: 0;
                    opacity: 1; } }

            @keyframes slide-in-right {
                from {
                    margin-left: 25%;
                    margin-right: -25%;
                    opacity: 0; }
                to {
                    margin-left: 0;
                    margin-right: 0;
                    opacity: 1; } } } } }
