.block.block-range {
  padding-top: 7*$base-width;
  .product-range {
    width: 100vw;
    height: 100vh;
    transition: 0.4s ease-out;
    right: 0;
    left: 0;
    margin-top: 0;
    z-index: 99999;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
      top: 0;
      left: auto;
      width: 65%;
      right: 3.2rem;
      min-width: unset;
      background: transparent;
      overflow-y: scroll; }
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
      position: static;
      flex: 0 0 100%;
      width: 100%;
      padding-top: 2.5*$base-width;
      height: unset; }

    .product-wrap {
      display: flex;
      flex-wrap: wrap;
      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        position: relative;
        flex-direction: unset;
        padding: 0;
        right: 0;
        top: 0;
        min-height: auto;
        overflow-y: scroll; }
      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        height: 100%; }



      .product {
        width: calc(100% * (1/4));
        height: calc((100vh - 96px) * (1/3));
        border: 1px solid #EDEDED;
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          border: unset;
          width: calc(100% * (1/2));
          padding-left: 5 *$base-width;
          max-height: 200px;
          height: 100%;
          justify-content: flex-start;
          padding-top: 2.5*$base-width; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
          padding-left: 2.5*$base-width; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          width: 100%;
          padding-top: 1rem; }

        a {
          display: block;
          height: 100%;
          width: 100%;
          position: relative;
          @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            display: flex;
            align-items: center; }
          .img-wrapper {
            padding-top: 2.5*$base-width;
            margin: 0 auto;
            height: calc(100% - 65px);
            position: relative;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
              margin: unset;
              max-width: 50px;
              padding-top: 0;
              height: unset; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
              max-width: 45px; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
              max-width: 30px; }

            // Coming Soon Cola
            &#img-wrapper-538 {
              position: unset;
              &:before {
                content: '';
                background-image: url(/wp-content/themes/bergauf/assets/img/layout/comingsoontuerkis.svg);
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 5;
                transform: translate(-50%, -50%);
                background-repeat: no-repeat;
                width: 65%;
                height: 65%; } }

            img {
              margin: 0 auto;
              width: auto;
              height: 100%;
              filter: drop-shadow(3px 3px 5px rgba(0, 0, 0,0.25 ));
              @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                height: unset; } }

            .splashimg {
              position: absolute;
              top: 0.88rem;
              right: 0;
              left: 0;
              bottom: 0;
              object-fit: contain;
              opacity: 0;
              transition: opacity .2s;
              max-width: 128px;
              @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                display: none; } } }

          .text-wrapper {
            text-align: center;
            padding: 1.875*$base-width 0;
            height: 65px;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
              padding: 0 2.5*$base-width;
              height: unset;
              padding-top: 45px; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
              padding-top: 25px; }
            .link-text {
              color: $light;
              text-shadow: none;
              font-size: 2rem; } }


          .no-image {
            background-color: $dark;
            height: calc(100% - 85px);
            width: 100%;
            max-width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            filter: drop-shadow(3px 3px 5px rgba(0, 0, 0,0.25 ));
            margin-top: 2.5*$base-width;
            p {
              font-size: 6rem;
              color: $light;
              opacity: 0.4;
              padding-bottom: 0;
              @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                font-size: 4rem; }
              @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                margin: unset;
                height: 136px;
                width: 50px; }
              @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                max-width: 30px;
                height: 82px; } } } }

        @media only screen and (min-width: #{map-get($breakpoints, m)}) {
          &:hover {
            transition: $transition;
            background-image: url(/wp-content/themes/bergauf/assets/img/backgrounds/background.jpg);
            background-size: 100%;
            a {
              transition: $transition;
              .text-wrapper {
                .link-text {
                  transition: $transition;
                  color: $yellow;
                  text-shadow: 0 0 20px $yellow; } }
              .img-wrapper {
                img {
                  transition: $transition;
                  filter: drop-shadow(3px 3px 5px #000); }
                .bottle {
                  opacity: 0; }
                .splashimg {
                  opacity: 1; }

                &.no-hover {
                  .bottle {
                    opacity: 1; } }

                // Coming soon cola
                &#img-wrapper-538 {
                  &:before {
                    transition: $transition;
                    background-image: url(/wp-content/themes/bergauf/assets/img/layout/comingsoonyellow.svg); } }

                .no-image {
                  filter: drop-shadow(3px 3px 5px #000); } } } } }

        &.active {
          a {
            color: $yellow;
            text-shadow: 0 0 20px $yellow; }
          button {
            svg {
              fill: $yellow;
              filter: drop-shadow( 0px 0px 20px rgba(248, 221, 33, 1)); } } }

        &:hover {
          transition: $transition;
          a {
            color: $hover;
            transition: $transition;
            text-shadow: 0 0 20px $hover; }
          button {
            svg {
              transition: $transition;
              fill: $hover; } } } } } } }

