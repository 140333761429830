#popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($dark,0.7);
  z-index: 9999;
  display: none;
  align-items: center;
  justify-content: center;
  height: 100vh;

  h3 {
    margin-bottom: 2.5*$base-width; }

  .btn {
    margin-top: 3*$base-width; }

  &.show {
    display: flex; }

  .popup {
    max-width: 1150px;
    margin: auto;
    background-image: url(/wp-content/themes/bergauf/assets/img/backgrounds/background.jpg);
    background-size: 100%;
    box-shadow: 0px 7px 55px rgba(255, 255, 255, .10);

    .button {
      padding: 0 6.25 * $base-width;
      position: relative;
      cursor: pointer;

      @media (max-width: #{map-get($breakpoints, lg)}) {
        margin-bottom: 0; }

      &:after, &:before {
        position: absolute;
        right: 5 * $base-width;
        content: ' ';
        height: 25px;
        width: 2px;
        top: 2.5 * $base-width;
        background-color: $yellow; }

      &:after {
        transform: rotate(45deg); }

      &:before {
        transform: rotate(-45deg); } }

    .popup-inner {
      padding: 8.75 * $base-width;
      display: flex;
      align-items: center;
      gap: 25px;
      box-shadow: 0px 7px 55px rgba(0, 0, 0, 0.15);

      @media (max-width: #{map-get($breakpoints, lg)}) {
        flex-wrap: wrap;
        padding: 5.5 * $base-width; }

      .infotext {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: #{map-get($breakpoints, lg)}) {
          flex-wrap: wrap; }

        svg {
          max-width: 38px;
          width: 100%;
          @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
            margin-bottom: 20px; } } }

      .image {
        padding-top: 35%;
        flex: 0 0 50%;
        position: relative;

        @media (max-width: #{map-get($breakpoints, lg)}) {
          flex: 0 0 100%;
          margin-top: 2*$base-width;
          padding-top: 46%; }

        @media (max-width: #{map-get($breakpoints, s)}) {
          padding-top: 60%; }

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center; } }

      .text-wrapper {
        padding-left: 5 * $base-width;

        @media (max-width: #{map-get($breakpoints, m)}) {
          padding-left: 0;
          padding-top: 15px; }

        h2 {
          font-size: 4rem;
          margin-bottom: 3rem;
          @media (max-width: #{map-get($breakpoints, l)}) {
            font-size: 2.8rem;
            margin-bottom: 2rem; } }

        p {
          padding: 0;
          font-size: 1.8rem;
          line-height: 1.7; } } } } }
