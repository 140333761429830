.block-recipe {
    padding: 22.5 *$base-width 2.5*$base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        padding: 12.5 * $base-width 2.5*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        padding: 6.25*$base-width 2.5*$base-width; }
    .recipe-media-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: $small;
        margin: 0 auto;
        position: relative;
        &.img-right {
            flex-direction: row-reverse; }

        &.img-left {
            flex-direction: row; }

        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            flex-direction: row-reverse; }

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            justify-content: center; }

        .media {
            z-index: 2;
            min-height: 100%;
            flex: 0 0 64%;
            width: 64%;
            padding-top: 85.511%;
            position: relative;
            opacity: 0;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                padding-top: 80%;
                width: 50%;
                flex: 0 0 50%; }
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding-top: 56.25%;
                flex: 0 0 50%;
                width: 50%; }
            @media only screen and (max-width: 1140px) {
                flex: 0 0 100%;
                width: 100%;
                padding-top: calc(87.81% / 2); }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                padding-top: 65%; }


            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;

                &.shadow {
                    width: 105%;
                    height: 105%;
                    opacity: .4;
                    filter: blur(50px);
                    position: absolute;
                    top: -2.5%;
                    bottom: 0px;
                    transform: scale(1.05);
                    left: 0;
                    z-index: -1; } } } // keep the .light under the .image

        .recipe {
            opacity: 0;
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 53%;
            transform: translate(0%,-50%);
            width: 100%;
            max-width: fit-content;
            background-color: #FFF6EA;
            overflow: hidden;
            padding: 7.5*$base-width 5*$base-width 12.5*$base-width 5*$base-width;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                position: relative;
                top: unset;
                left: unset;
                transform: none; }
            @media only screen and (max-width: 1140px) {
                max-width: unset; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding: 5*$base-width 2.5*$base-width 15*$base-width 2.5*$base-width; }
            .inner-recipe {
                position: relative;
                svg.note {
                    position: absolute;
                    transform: translate(-35%, -38%);
                    max-height: 180px; } }

            .headline-wrapper {
                max-width: fit-content; }
            .preline {
                font-size: 5rem;
                padding-bottom: 0;
                color: #000;
                max-width: fit-content;
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    font-size: 3rem; } }

            h2, .h2 {
                color: #000;
                text-shadow: none;
                font-size: 7rem;
                margin-bottom: 3.75*$base-width;
                max-width: fit-content;
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    margin-bottom: 2.5*$base-width;
                    font-size: 5rem; } }

            p {
                color: #000;
                padding-bottom: 5 * $base-width;
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    padding-bottom: 3.75*$base-width; } }

            .ingredient {
                display: flex;
                padding-bottom: 10px;
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    padding-bottom: 0.5rem; }
                p {
                    padding-bottom: 0;
                    padding-left: 10px; }
                svg {
                    fill: #3DC3B1;
                    width: 25px; } } } }

    &.in-view {
        .recipe {
            animation: slide-in-content-al 1.5s ease forwards; }

        .media {
            animation: slide-in-gallery-al 1.5s ease forwards;

            @keyframes slide-in-content-al {
                from {
                    margin-right: -25%;
                    margin-left: 25%;
                    opacity: 0; }

                to {
                    margin-right: 0;
                    margin-left: 0;
                    opacity: 1; } }

            @keyframes slide-in-gallery-al {
                from {
                    margin-left: -25%;
                    margin-right: 25%;
                    opacity: 0; }

                to {
                    margin-left: 0;
                    margin-right: 0;
                    opacity: 1; } } } } }
