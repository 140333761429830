/*Variables: Form * */

$success: green;
$error: red;

.block-form {
  .wrapper {
    max-width: $small;
    margin: 0 auto;
    padding: 0 2.5*$base-width;
    padding-top: 30*$base-width;
    position: relative;
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
      padding-top: 22.5*$base-width; }

    .form-intro {
      .headline-wrapper {
        &.align-center {
          max-width: 900px;
          margin: 0 auto;
          text-align: center;
          padding-bottom: 12.5*$base-width;
          @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            padding-bottom: 6.25*$base-width; }
          @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding-bottom: 3.75*$base-width; } }
        h1, .h1 {
            text-transform: uppercase;
            line-height: 0.8;
            margin-bottom: 0;
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
              text-shadow: unset; } } }

      .text-wrapper {
        max-width: 750px;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 12.5*$base-width;
        .text {
          padding-bottom: 5*$base-width;
          @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding-bottom: 3.75*$base-width; } } } }

    form {
      .form-inner {
        .form-field-group {
          margin: 4 * $base-width 0;
          position: relative;

          label {
            display: inline-block; }

          input[type="text"] {
            width: 100%;
            height: 5 * $base-width;
            padding: 1.25 * $base-width 2.5 *$base-width;
            background-color: transparent;
            border: none;
            color: #fff;
            &:focus-visible {
              outline: none; } }

          textarea {
            width: 100%;
            margin-top: 2.5*$base-width;
            min-height: 37.5 * $base-width;
            padding: 2.5 * $base-width;
            background-color: transparent;
            resize: none;
            border: none;
            color: #fff;
            &:focus-visible {
              outline: none; }

            background-image: repeating-linear-gradient(0deg, #3F3F3F, #3F3F3F 10px, transparent 10px, transparent 20px, #3F3F3F 20px), repeating-linear-gradient(90deg, #3F3F3F, #3F3F3F 10px, transparent 10px, transparent 20px, #3F3F3F 20px), repeating-linear-gradient(180deg, #3F3F3F, #3F3F3F 10px, transparent 10px, transparent 20px, #3F3F3F 20px), repeating-linear-gradient(270deg, #3F3F3F, #3F3F3F 10px, transparent 10px, transparent 20px, #3F3F3F 20px);
            background-size: 1.5px 100%, 100% 1.5px, 1.5px 100% , 100% 1.5px;
            background-position: 0 0, 0 0, 100% 0, 0 100%;
            background-repeat: no-repeat; }

          &.textfield {
            margin-bottom: 6.25*$base-width;
            &:after {
              content: '';
              width: 100%;
              height: 100%;
              position: absolute;
              bottom: -49%;
              transform: translate(0, 49%);
              left: 0;
              background-image: linear-gradient(to right, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
              background-size: 20px 1px;
              background-repeat: repeat-x; } }

          .checkbox-text {
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
              font-size: 1.6rem;
              line-height: 1.6;
              max-width: 90%; } }
          .checkbox {
            display: flex;

            input {
              width: 23px;
              height: 23px;
              background-color: #fff;
              border-radius: 50%;
              vertical-align: middle;
              border: unset;
              appearance: none;
              -webkit-appearance: none;
              outline: none;
              cursor: pointer;
              position: relative;
              margin-right: 10px;
              @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                  width: 18px;
                  height: 18px; }
              &:after {
                  content: '';
                  background-image: url('/wp-content/themes/bergauf/blocks/tastefinder/form-steps/svg/check.svg');
                  position: absolute;
                  width: 25px;
                  height: 25px;
                  background-repeat: no-repeat;
                  margin-left: 2px;
                  opacity: 0;
                  @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                      width: 20px;
                      height: 20px; } }

              &:checked {
                background-color: $hover;
                + label {
                  color: $hover; }
                + .svg-top {
                  opacity: 0.22; }
                + .svg-bottom {
                  opacity: 0.22; }
                &:after {
                  opacity: 1; } }

              &.error {
                &::after {
                  content: '';
                  width: 25px;
                  height: 25px;
                  position: absolute;
                  background-color: rgba($error,.25); }

                &:checked {
                  &::after {
                    background-color: rgba($error,.0); } } } } } }

        #confirm {
          position: absolute;
          left: -20000px; } }

      .form-success,
      .form-error {
        width: 100%;
        border: 2px solid $error;
        background-color: rgba($error,.2);
        padding: 3rem;
        margin-bottom: 3rem; }

      .form-success {
          border: 2px solid $success;
          background-color: rgba($success,.25); }

      .error {
        border-color: $error;
        background-color: rgba($error,.25); } } }

  .required {
    padding-top: 20px;
    text-align: right; } }
