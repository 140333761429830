.sidelayer {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
    top: 260px;
    right: 0;
    position: fixed;
    z-index: 99;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        display: none; }

    .side-item {
        background-color:  rgba(255, 255, 255, 0.15);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 1;
            svg {
                width: 100%;
                height: 100%; } }

        &:hover {
            transition: $transition;
            background-color: #000000;
            box-shadow: 0 0 15px $hover;
            a {
                svg {
                    path {
                        fill: $hover;
                        transition: $transition; } } } } } }
