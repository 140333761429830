.block-text-columns {
    max-width: $boxed;
    margin: 0 auto;
    padding: $pboxed;
    .text-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .text {
            flex: 0 0 48%;

            @media only screen and (max-width: #{map-get($breakpoints, md)}) {
                flex: 0 0 100%; } } }

    ul {
        padding-left: 3 * $base-width;

        li {
            margin-bottom: $base-width; } }

    .btn {
        margin: 5 * $base-width 0; } }
