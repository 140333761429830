.block-instagram {
    padding: 18.75*$base-width 0;
    .wrapper {
        max-width: $boxed;
        margin: 0 auto;
        padding: 0 2.5*$base-width;
        position: relative;
        .hl-wrapper {
            .headline-wrapper {
                max-width: 900px;
                margin: 0 auto;
                text-align: center;
                padding-bottom: 6.25*$base-width;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    padding-bottom: 0; }
                h2, .h2 {
                    text-transform: uppercase;
                    line-height: 0.8;
                    margin-bottom: 0;
                    font-size: 11rem;
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        font-size: 8rem;
                        margin-bottom: 4.375 * $base-width; }
                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                        font-size: 6rem;
                        margin-bottom: 3.75 * $base-width; } } }
            .social {
                display: flex;
                justify-content: center;
                align-items: center;
                .item {
                    padding: 0 10px;
                    &:hover {
                        svg {
                            transition: $transition;
                            box-shadow: 0 0 15px $hover;
                            path {
                                fill: $hover;
                                transition: $transition; } } } } } } } }



