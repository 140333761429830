// html.open-nav
//     @media only screen and (max-width: #{map-get($breakpoints, xl)})
//         overflow: hidden

//     body
//         @media only screen and (max-width: #{map-get($breakpoints, xl)})
//             pointer-events: none

//         &:before
//             display: block

header {
    width: 100%;
    max-width: 100vw;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    background-color: #000000;

    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        pointer-events: auto; }

    .holder {
        position: relative; }
    .header-inner {
        padding-right: 2.5*$base-width;
        padding-left: 2.5*$base-width;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: $boxed;
        height: 12*$base-width;
        margin: 0 auto;


        .logo {
            display: inline-flex;

            svg {
                width: 260px;
                height: auto;

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    width: 200px; }

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    width: 160px; } } }

        nav {
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            font-weight: 500;
            height: 100%;
            display: flex;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                padding-left: 1.5*$base-width; }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                order: 2;
                justify-content: flex-end;
                position: static;
                font-size: 2rem; }

            ul {
                display: flex;
                height: 100%;
                align-items: center;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    position: absolute;
                    right: -200%;
                    top: 96px;
                    transition: $transition;
                    background-color: $black;
                    padding: 8*$base-width 3*$base-width;
                    width: 100%;
                    min-height: 100vh;
                    display: flex;
                    flex-direction: column;
                    height: unset;
                    align-items: flex-start;
                    justify-content: flex-start;
                    z-index: 99;
                    overflow-y: scroll;

                    &.open {
                        right: 0; } }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    padding: 6*$base-width 2*$base-width;
                    height: 100%; }

                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    top: 70px;
                    padding: 2*$base-width;
                    height: calc(100vh - 70px);
                    min-height: unset; }


                li {
                    padding: 0 3.75*$base-width;
                    list-style-type: none;
                    display: flex;
                    height: 100%;
                    align-items: center;
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        padding: 1.875*$base-width 0;
                        height: fit-content; }
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        display: unset; }

                    &:last-child {
                        padding-right: 0; }
                    a {
                        color: $light;
                        font-family: $headline-font;
                        font-size: 2.5rem;
                        text-transform: uppercase;
                        text-decoration: none; }

                    &.children {
                        button {
                            padding-left: 7px;
                            background: none;
                            border: none;
                            svg {
                                width: 13px;
                                height: 20px; } }

                        ul.level-1 {
                            display: none; } }


                    &.show {
                        transition: $transition;
                        a {
                            color: $hover;
                            text-shadow: 0 0 20px $hover; }
                        button {
                            svg {
                                transition: $transition;
                                fill: $hover; } }

                        .dropdown-wrapper {
                            background-color: #fff;
                            width: 100vw;
                            top: 96px;
                            height: calc(100vh - 96px);
                            position: absolute;
                            transition: 0.4s ease-out;
                            right: 0;
                            left: 0;
                            margin-top: 0;
                            z-index: 99999;
                            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                top: 0;
                                left: auto;
                                width: 65%;
                                right: 3.2rem;
                                min-width: unset;
                                background: transparent;
                                overflow-y: scroll; }
                            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                position: static;
                                flex: 0 0 100%;
                                width: 100%;
                                padding-top: 2.5*$base-width;
                                height: unset; } }


                        ul.level-1 {
                            display: flex;
                            flex-wrap: wrap;
                            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                position: relative;
                                flex-direction: unset;
                                padding: 0;
                                right: 0;
                                top: 0;
                                min-height: auto;
                                overflow-y: scroll; }
                            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                height: 100%; }



                            li {
                                width: calc(100% * (1/5));
                                height: calc((100vh - 96px) * (1/3));
                                border: 1px solid #EDEDED;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                &:last-child {
                                    padding: 0 3.75*$base-width; }
                                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                    border: unset;
                                    width: calc(100% * (1/2));
                                    padding-left: 5 *$base-width;
                                    max-height: 200px;
                                    height: 100%;
                                    justify-content: flex-start;
                                    padding-top: 2.5*$base-width;
                                    &:last-child {
                                        padding-top: 2.5*$base-width; } }
                                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                    padding-left: 2.5*$base-width;
                                    &:last-child {
                                        padding-left: 2.5*$base-width; } }
                                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                    width: 100%;
                                    padding-top: 1rem; }



                                a {
                                    display: block;
                                    height: 100%;
                                    width: 100%;
                                    position: relative;
                                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                        display: flex;
                                        align-items: center; }
                                    .img-wrapper {
                                        padding-top: 2.5*$base-width;
                                        margin: 0 auto;
                                        height: calc(100% - 65px);
                                        position: relative;
                                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                            margin: unset;
                                            max-width: 50px;
                                            padding-top: 0;
                                            height: unset; }
                                        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                            max-width: 45px; }
                                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                            max-width: 30px; }

                                        // Coming Soon Cola
                                        // &#img-wrapper-538
                                        //     position: unset
                                        //     &:before
                                        //         content: ''
                                        //         background-image: url(/wp-content/themes/bergauf/assets/img/layout/comingsoontuerkis.svg)
                                        //         position: absolute
                                        //         top: 50%
                                        //         left: 50%
                                        //         z-index: 5
                                        //         transform: translate(-50%, -50%)
                                        //         background-repeat: no-repeat
                                        //         width: 65%
                                        //         height: 65%

                                        img {
                                            margin: 0 auto;
                                            width: auto;
                                            height: 100%;
                                            filter: drop-shadow(3px 3px 5px rgba(0, 0, 0,0.25 ));
                                            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                                height: unset; } }

                                        .splash {
                                            position: absolute;
                                            top: 0.88rem;
                                            right: 0;
                                            left: 0;
                                            bottom: 0;
                                            object-fit: contain;
                                            opacity: 0;
                                            transition: opacity .2s;
                                            max-width: 128px;
                                            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                                display: none; } } }

                                    .text-wrapper {
                                        text-align: center;
                                        padding: 1.875*$base-width 0;
                                        height: 65px;
                                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                            padding: 0 2.5*$base-width;
                                            height: unset;
                                            padding-top: 45px; }
                                        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                            padding-top: 25px; }
                                        .link-text {
                                            color: $black;
                                            text-shadow: none;
                                            font-size: 2rem;
                                            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                                color: $light; } } }

                                    .no-image {
                                        background-color: $dark;
                                        height: calc(100% - 85px);
                                        width: 100%;
                                        max-width: 80px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        margin: 0 auto;
                                        filter: drop-shadow(3px 3px 5px rgba(0, 0, 0,0.25 ));
                                        margin-top: 2.5*$base-width;
                                        p {
                                            font-size: 6rem;
                                            color: $light;
                                            opacity: 0.4;
                                            padding-bottom: 0;
                                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                                font-size: 4rem; } }
                                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                            margin: unset;
                                            height: 136px;
                                            width: 50px; }
                                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                            max-width: 30px;
                                            height: 82px; } } }
                                @media only screen and (min-width: #{map-get($breakpoints, m)}) {
                                    &:hover, &.active {
                                        transition: $transition;
                                        background-image: url(/wp-content/themes/bergauf/assets/img/backgrounds/background.jpg);
                                        background-size: 100%;
                                        a {
                                            transition: $transition;
                                            .text-wrapper {
                                                .link-text {
                                                    transition: $transition;
                                                    color: $yellow;
                                                    text-shadow: 0 0 20px $yellow; } }
                                            .img-wrapper {
                                                img {
                                                    transition: $transition;
                                                    filter: drop-shadow(3px 3px 5px #000); }
                                                .bottle {
                                                    opacity: 0; }
                                                .splash {
                                                    opacity: 1; }

                                                &.no-hover {
                                                    .bottle {
                                                        opacity: 1; } }

                                                // // Coming soon cola
                                                // &#img-wrapper-538
                                                //     &:before
                                                //         transition: $transition
 }                                                //         background-image: url(/wp-content/themes/bergauf/assets/img/layout/comingsoonyellow.svg)


                                            .no-image {
                                                filter: drop-shadow(3px 3px 5px #000); } } } } } }



                        // button
                        //     svg
                        //         fill: #F8DD21
 }                        //         filter: drop-shadow( 0px 0px 20px rgba(248, 221, 33, 1))


                    &.active {
                        a {
                            color: $yellow;
                            text-shadow: 0 0 20px $yellow; }
                        button {
                            svg {
                                fill: $yellow;
                                filter: drop-shadow( 0px 0px 20px rgba(248, 221, 33, 1)); } } }

                    &:hover {
                        transition: $transition;
                        a {
                            color: $hover;
                            transition: $transition;
                            text-shadow: 0 0 20px $hover; }
                        button {
                            svg {
                                transition: $transition;
                                fill: $hover; } } } }

                &#main-menu-recycle {
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        position: relative;
                        right: unset;
                        top: unset;
                        padding: unset;
                        display: flex;
                        height: 100%;
                        align-items: center;
                        width: 100%;
                        min-height: 100%;
                        justify-content: center;
                        overflow-y: unset; } } } }



        .navbar-toggler {
            display: none;
            background: transparent;
            border: none;
            .toggle {
                background: none;
                border-radius: 100%;
                height: 50px;
                width: 50px;
                position: relative; }

            .line-toggle {
                height: 2px;
                background-color: $light;
                width: 40px;
                border-radius: 8px;
                display: inline-block;
                position: absolute;
                margin: 0 auto;

                &.top {
                    top: 15px;
                    left: 0;
                    right: 0;
                    transition: $transition; }

                &.middle {
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    transition: $transition; }

                &.bottom {
                    bottom: 15px;
                    left: 0;
                    right: 0;
                    transition: $transition; } }
            &.open {
                .line-toggle {
                    transition: $transition;
                    background-color: $yellow;
                    box-shadow: 0 0 20px $yellow;
                    &.middle {
                        opacity: 0; }

                    &.top {
                        top: 50%;
                        transform: rotate(45deg) translateY(-50%);
                        transition: $transition; }

                    &.bottom {
                        top: 50%;
                        bottom: unset;
                        transform: rotate(-45deg) translateY(-50%);
                        transition: $transition; } } }

            // &:hover
            //     .line-toggle
            //         background-color: $hover
            //         box-shadow: 0 0 20px $hover

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                display: flex;
                align-items: center;
                z-index: 99; } } } }
