// Base
$base-width: .8rem;

// Fonts
$font-default: 'Lato';
$headline-font: 'TradeGothic';
$preline-font: 'missrobertson-pro';

// General
$dark: #222;
$light: #fff;

// Brand Colors
$primary: #002b22;
$secondary: #8cd60a;
$yellow: #F8DD21;
$green: #29B08E;
$blue: #64C5E1;
$berry: #951B81;
$red: #E40929;
$orange: #CC6E16;
$black: #000;
$hover: #3DC3B1;

// Others
$divider: rgba($dark, .3);

// Padding
$pboxed: 0 20px;


// Widths
$max: 2560px;
$boxed: 1640px;
$small: 1263px;

// Transitions
$transition: all .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "s": 576px, "m": 768px, "l": 992px, "lg": 1050px, "xl": 1250px, "xxl": 1400px, "xxxl": 1680px);
