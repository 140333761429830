.block-range-hero {
  max-width: $max;
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: 8.75*$base-width;
  @media only screen and (max-width: #{map-get($breakpoints, m)}) {
    padding-bottom: 0; }
  .heroimage-inner {
    height: 100vh;
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
      height: 80vh; }
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
      height: 100%; }
    .img-wrapper {
      overflow: hidden;
      z-index: -1;
      width: 100%;
      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        width: 100%;
        max-height: 400px;
        margin-top: 12*$base-width; }
      img {
        width: 100%;
        object-fit: cover; } }

    .text-image-wrapper {
      z-index: 3;
      position: absolute;
      bottom: 10%;
      opacity: 0;
      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        justify-content: center; }
      @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        bottom: 6%; }
      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        position: relative;
        bottom: unset;
 }        // justify-content: unset

      //animation
      ////ANIMATION
      &.in-view {
        animation: 1.3s slide-in-hero ease forwards;

        @keyframes slide-in-hero {
          from {
            margin-left: -25%;
            margin-right: 25%;
            opacity: 0; }

          to {
            margin-left: 0;
            margin-right: 0;
            opacity: 1; } } }


      .inner-wrapper {
        position: relative;
        display: flex;
        padding-left: 10rem;
        width: calc(100% + 260px);
        flex-direction: row;
        background: url(/wp-content/themes/bergauf/assets/img/backgrounds/background.jpg);
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          padding: 0 2.5*$base-width;
          width: 100%; }
        .productimage {
          max-width: 260px;
          width: 100%;
          position: relative;
          @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            min-width: 200px;
            width: auto; }
          @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            display: none; }
          .productpicture {
            position: absolute;
            bottom: -50px;
            left: 0;
            width: 100%;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
              min-width: 200px; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
              display: none; }
            img {
              width: 100%;
              height: auto;
              @media only screen and (max-width: 1800px) {
                width: 90%; }
              @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                width: 80%; } } } }
        .headline-wrapper {
          padding: 7.5*$base-width 12.5*$base-width;
          @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            padding: 7.5*$base-width; }
          @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            width: 100%;
            padding: 7.5*$base-width 0; }
          @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 4*$base-width 0; }

          h1, .h1 {
            text-transform: uppercase;
            font-size: 10rem;
            max-width: 800px;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
              font-size: 8rem;
              margin-bottom: 4.375 * $base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
              font-size: 7rem;
              margin-bottom: 4* $base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
              font-size: 6.5rem;
              margin-bottom: 2.5 * $base-width;
              text-shadow: unset; }
            @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
              font-size: 5rem; } }

          .subline {
            max-width: 80%;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
              font-size: 2.4rem; }
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
              font-size: 2.2rem;
              margin-bottom: 2.5 *$base-width;
              max-width: 100%; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
              font-size: 2rem;
              margin-bottom: 0; } }

          .preline {
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
              font-size: 4.8rem; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
              font-size: 3.5rem; }
            @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
              font-size: 3rem; } } } } } } }
