// TradeGothic - Headline
@font-face {
    font-family: 'TradeGothic';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/TradeGothicLTStd.woff2') format('woff2'),
    url('../../fonts/TradeGothicLTStd.woff') format('woff') {}
    font-display: swap; }

// Lato Light
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/lato-light.woff2') format('woff2'),
    url('../../fonts/lato-light.woff') format('woff') {}
    font-display: swap; }

// Lato Regular
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/lato-regular.woff2') format('woff2'),
    url('../../fonts/lato-regular.woff') format('woff') {}
    font-display: swap; }

// Lato Medium
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/lato-medium.woff2') format('woff2'),
    url('../../fonts/lato-medium.woff') format('woff') {}
    font-display: swap; }

// Lato Bold
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/lato-bold.woff2') format('woff2'),
    url('../../fonts/lato-bold.woff') format('woff') {}
    font-display: swap; }

//Miss Robertson Pro Regular
@font-face {
    font-family: 'missrobertson-pro';
    font-weight: 400;
    font-style: normal; }
