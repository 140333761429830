footer {
    padding: 18.75 * $base-width 0;
    @media (max-width: #{map-get($breakpoints, xl)}) {
        padding: 15 * $base-width 0; }
    @media (max-width: #{map-get($breakpoints, m)}) {
        padding: 12.5 * $base-width 0; }
    @media (max-width: #{map-get($breakpoints, s)}) {
        padding: 10 * $base-width 0; }
    .row {
        display: flex;
        max-width: $boxed;
        padding: $pboxed;
        margin: auto;
        @media (max-width: #{map-get($breakpoints, xl)}) {
            flex-wrap: wrap; }
        .cols {
            font-family: $font-default;
            font-size: 1.8rem;
            font-weight: 300;
            &:first-child {
                margin-right: 12.5*$base-width; }
            @media (max-width: #{map-get($breakpoints, xl)}) {
                padding-bottom: 7.5*$base-width;
                width: calc(100% * (1/2));
                &:first-child {
                    margin-right: unset; } }

            @media (max-width: #{map-get($breakpoints, l)}) {
                width: 100%; }

            @media (min-width: #{map-get($breakpoints, xl)}) {
                width: calc(100% * (1/4));
                &:last-child {
                    min-width: 565px;
                    padding-right: 0; } }

            h4 {
                font-weight: 500;
                font-size: 2.5rem;
                text-transform: uppercase;
                margin-bottom: 40px; }

            a {
                color: #fff;
                text-decoration: none;
                display: flex;
                align-items: center;
                max-width: fit-content;
                svg {
                    margin-right: 10px; }
                &.btn-footer {
                    font-weight: 500;
                    display: flex;
                    position: relative;
                    svg {
                        width: 29px;
                        height: 29px;
                        padding: 6px 10px;
                        border: 2px solid #fff;
                        border-radius: 50px;
                        margin-left: 12px;
                        margin-right: 0; }
                    &:hover {
                        color: $hover;
                        transition: $transition;
                        text-shadow: 0 0 15px $hover;
                        svg {
                            transition: $transition;
                            // background-color: $hover
                            border: 2px solid $hover;
                            box-shadow: 0 0 15px $hover; } } }
                &:hover {
                    color: $hover;
                    text-shadow: 0 0 15px $hover;
                    transition: $transition;
                    svg {
                        transition: $transition;
                        path {
                            fill: $hover;
                            box-shadow: 0 0 15px $hover;
                            transition: $transition; } } } }

            .footer-item {
                padding-bottom: 0.8rem;
                &:last-child {
                    padding-bottom: 0; } }

            .adress-block {
                padding-bottom: 3rem;
                p {
                    padding-bottom: 0;
                    line-height: calc(10px + 1.8rem + 2px);
                    &.semibold {
                        font-weight: 700;
                        padding-bottom: 2.5rem;
                        max-width: 250px; } } }

            .social {
                .footer-item {
                    padding-bottom: 1.2rem; } }

            &.legal {
                ul.footer-menu {
                    list-style-type: none;
                    li {
                        padding-bottom: 0.8rem; } } } } } }


