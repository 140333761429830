* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: scroll; }

body {
    -webkit-text-size-adjust: none;
    font-size: 2rem;
    color: $light;
    line-height: 160%;
    font-weight: 400;
    font-family: $font-default;
    overflow-x: hidden;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.77)), url(/wp-content/themes/bergauf/assets/img/backgrounds/background.jpg);
    background-size: 100%;
    background-repeat: repeat-y;
    background-attachment: fixed; }

img {
    display: block;
    max-width: 100%; }

main,
.block {
    position: relative; }


a:not(.btn) {
    @media (max-width: #{map-get($breakpoints, s)}) {
        overflow-wrap: break-word; } }


.btn.btn-primary {
    color: $light;
    display: inline-block;
    border: 2px solid $light;
    padding: 2.5 * $base-width 3.75 * $base-width;
    font-family: $headline-font;
    font-size: 2.2rem;
    border-radius: 40px;
    text-decoration: none;
    line-height: 1;
    background-color: unset;
    cursor: pointer;
    &:hover {
        transition: $transition;
        background-color: $hover;
        border: 2px solid $hover;
        box-shadow: 0 0 15px $hover; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 1.7rem;
        padding: 2.5 * $base-width;
        line-height: 0.8;
        padding-bottom: 18px; } }
