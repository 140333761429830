.block-text-slider-teaser {
    padding: 18.75*$base-width 0;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        padding-bottom: 0; }
    .text-slider-wrapper {
        display: flex;
        align-content: stretch;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: $boxed;
        margin: 0 auto;

        &.slider-right {
            flex-direction: row-reverse; }

        &.slider-left {
            flex-direction: row; }

        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            flex-direction: row-reverse;
            justify-content: center; }

        .slider-wrapper {
            flex: 0 0 50%;
            width: 50%;
            max-width: 820px;
            position: relative;
            margin-top: 98.5px;
            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                padding: 0 2.5*$base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                flex: 0 0 100%;
                width: 100%;
                margin-bottom: 15*$base-width;
                margin-top: 0; }


            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                margin-bottom: 10*$base-width; } }


        .textslide {
            .owl-stage-outer {
                margin: auto;
                z-index: 1;
                .slider-item {
                    display: flex;
                    max-width: 715px;
                    flex-direction: column;
                    justify-content: center;

                    .text-wrapper {
                        max-width: 600px; } } }

            .owl-nav {
                display: flex;
                position: absolute;
                z-index: 10;
                padding-top: 8.75*$base-width;
                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    padding-top: 5*$base-width; }
                button {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    transition: $transition;
                    border-radius: 50%;
                    border: 2px solid $light;
                    @media (max-width: #{map-get($breakpoints, l)}) {
                        width: 40px;
                        height: 40px;
                        margin: 0 10px; }
                    .owl-carousel-arrow {
                        background-color: transparent;
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 8px;
                            height: auto;
                            transition: $transition;
                            background-color: transparent;
                            border-radius: 50%; } }
                    &:hover {
                        background-color: $hover;
                        border: 2px solid $hover;
                        box-shadow: 0 0 15px $hover; }

                    &.owl-next {
                        margin-left: 10px; } } } }



        .teaser-wrapper {
            z-index: 2;
            flex: 0 0 50%;
            width: 50%;
            position: relative;
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(to bottom, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
                background-size: 1px 20px;
                background-repeat: repeat-y; }

            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                flex: 0 0 100%;
                width: 100%;

                &:before {
                    display: none; } }
            a {
                text-decoration: none; }
            .teaseritem {
                position: relative;
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    padding: 0 2.5*$base-width; }

                .teaser {
                    position: relative;
                    display: flex;
                    align-items: center;
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: -2;
                        opacity: 0;
                        width: 100%;
                        object-fit: cover;
                        height: 100%; }
                    .text {
                        width: 65%;
                        margin: auto;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            width: 70%; }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            width: 85%; }
                        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                            width: 100%; }

                        .preline {
                            color: $light; } } }
                &:hover {
                    .headline-wrapper {
                        .preline {
                            color: $light; }
                        h2 {
                            transition: $transition;
                            color: $yellow;
                            text-shadow: 0 0 20px $yellow; } }
                    &:first-child {
                        img {
                            transition: $transition;
                            opacity: 0.15; } }
                    &:last-child {
                        img {
                            transition: $transition;
                            opacity: 0.44;
                            max-width: 768px;
                            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                                max-width: unset; } }
                        picture {
                            &::before {
                                content: '';
                                opacity: 1;
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: -1;
                                background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0.8) 35%, rgba(0, 0, 0, 0) 100%); } } }

                    .btn {
                        transition: $transition;
                        background-color: $hover;
                        border: 2px solid $hover;
                        box-shadow: 0 0 15px $hover; } }

                &:first-child {
                    .teaser {
                        height: 510px;
                        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                            height: 450px; }
                        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                            height: 380px; }
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            height: 350px; } } }

                &:last-child {
                    .teaser {
                        height: 433px;
                        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                            height: 400px; }
                        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                            height: 380px; }
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            height: 350px; } }
                    &:before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        max-width: 514px;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        background-image: linear-gradient(to right, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
                        background-size: 20px 1px;
                        background-repeat: repeat-x; } } } } } }
