/* Globals */
@import './globals/vars';
@import './globals/fonts';
@import './globals/base';
@import '~owl.carousel/dist/assets/owl.carousel';

/* Layout-Parts */
@import './layout/typo';
@import './layout/header';
@import './layout/footer';
@import './layout/sidebar';
@import './layout/404';
@import './layout/popup';

/* Third-Party */
@import url("https://use.typekit.net/wnm2kgo.css");

/* Blocks */
@import '../../blocks/text-media/style';
@import '../../blocks/text-slider-teaser/style';
@import '../../blocks/hero/style';
@import '../../blocks/recipe/style';
@import '../../blocks/history/style';
@import '../../blocks/drink-facts/style';
@import '../../blocks/range-hero/style';
@import '../../blocks/product-slider/style';
@import '../../blocks/opener/style';
@import '../../blocks/tastefinder/style';
@import '../../blocks/instagram/style';
@import '../../blocks/text-columns/style';
@import '../../blocks/teaser/style';
@import '../../blocks/auto-instagram-fetch/style';
@import '../../blocks/form/style';
@import '../../blocks/range/style';
@import '../../blocks/store-locator/style';
