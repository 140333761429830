.block-text-media {
    padding: 18.75 * $base-width 0;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        padding: 10 * $base-width 0; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding: 6.25 * $base-width 0; }
    .wrapper {
        display: flex;
        align-content: stretch;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: $boxed;
        margin: 0 auto;
        padding: 0 2.5*$base-width;
        &.img-right {
            flex-direction: row-reverse; }

        &.img-left {
            flex-direction: row; }

        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            flex-direction: row-reverse; } }

    .text {
        align-content: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex: 0 0 50%;
        width: 50%;
        max-width: 675px;
        opacity: 0;

        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            min-height: unset;
            margin-top: 8 * $base-width;
            padding: 0;
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%; }

        &.img-right {
            padding-left: 0; }

        &.img-left {
            padding-right: 0; } }

    .media {
        z-index: 2;
        min-height: 100%;
        flex: 0 0 50%;
        width: 50%;
        padding-top: calc(87.81% / 2);
        position: relative;
        opacity: 0;

        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            flex: 0 0 100%;
            width: 100%; }
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            padding-top: 65%; }

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;

            &.shadow {
                width: 105%;
                height: 105%;
                opacity: .4;
                filter: blur(50px);
                position: absolute;
                top: -2.5%;
                bottom: 0px;
                transform: scale(1.05);
                left: 0;
                z-index: -1; // keep the .light under the .image
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    top: 0; } } }

        .ingredient {
            position: absolute;
            // img
            svg {
                position: relative;
                max-width: 230px;
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    max-width: 180px; }
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    max-width: 150px; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    max-width: 100px; } }
            &.top-right {
                right: 0;
                top: 0;
                transform: translate(30%, -75%); }
            &.bottom-left {
                bottom: 0;
                left: 0;
                transform: translate(-35%, 10%); } } }



    &.in-view {
        .img-left {
            .text {
                animation: slide-in-content-al 1.5s ease forwards; }

            .media {
                animation: slide-in-gallery-al 1.5s ease forwards;

                @keyframes slide-in-content-al {
                    from {
                        margin-right: -25%;
                        margin-left: 25%;
                        opacity: 0; }

                    to {
                        margin-right: 0;
                        margin-left: 0;
                        opacity: 1; } }

                @keyframes slide-in-gallery-al {
                    from {
                        margin-left: -25%;
                        margin-right: 25%;
                        opacity: 0; }

                    to {
                        margin-left: 0;
                        margin-right: 0;
                        opacity: 1; } } } } }

    // Animation
    &.in-view {
        .text {
            animation: slide-in-content 1.5s ease forwards; }

        .media {
            animation: slide-in-gallery 1.5s ease forwards;

            @keyframes slide-in-content {
                from {
                    margin-left: -25%;
                    margin-right: 25%;
                    opacity: 0; }

                to {
                    margin-left: 0;
                    margin-right: 0;
                    opacity: 1; } }

            @keyframes slide-in-gallery {
                from {
                    margin-right: -25%;
                    margin-left: 25%;
                    opacity: 0; }

                to {
                    margin-right: 0;
                    margin-left: 0;
                    opacity: 1; } } } }

    &.recycle {
        padding: 18.75 * $base-width 0 0;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            padding: 15 * $base-width 0 0; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 15 * $base-width 0 0; }
        h1 {
            font-family: $headline-font, Tahoma, Verdana, sans-serif;
            line-height: 1;
            display: block;
            font-weight: 400;
            color: $light;
            text-shadow: 3px 0 7px $yellow;
            text-transform: uppercase;
            font-size: 6rem;
            margin-bottom: 5 * $base-width;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                font-size: 4rem;
                margin-bottom: 3.75 * $base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                font-size: 3.5rem;
                margin-bottom: 3.75 * $base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                font-size: 3rem;
                text-shadow: 0 0 3px $yellow;
                margin-bottom: 2.5*$base-width; } }

        .wrapper {
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                justify-content: center; }
            .media {
                z-index: 2;
                min-height: 100%;
                flex: 0 0 40%;
                width: 50%;
                padding-top: calc(87.81% / 2);
                position: relative;
                opacity: 0;

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    flex: 0 0 50%;
                    width: 50%; }
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    padding-top: 65%; } }
            .text {
                align-content: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                flex: 0 0 60%;
                width: 50%;
                max-width: 950px;
                opacity: 0;

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    flex: 0 0 100%;
                    width: 100%;
                    max-width: 100%; }

                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    margin-top: 3.75 * $base-width; } } } }

    &.recyclecb {
        padding: 18.75 * $base-width 0 0;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            padding: 15 * $base-width 0 0; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 15 * $base-width 0 0; }
        h1 {
            font-family: $headline-font, Tahoma, Verdana, sans-serif;
            line-height: 1;
            display: block;
            font-weight: 400;
            color: $light;
            text-shadow: 3px 0 7px $yellow;
            text-transform: uppercase;
            font-size: 6rem;
            margin-bottom: 5 * $base-width;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                font-size: 4rem;
                margin-bottom: 3.75 * $base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                font-size: 3.5rem;
                margin-bottom: 3.75 * $base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                font-size: 3rem;
                text-shadow: 0 0 3px $yellow;
                margin-bottom: 2.5*$base-width; } }

        .wrapper {
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                justify-content: center; }
            .media {
                z-index: 2;
                min-height: 100%;
                flex: 0 0 55%;
                width: 50%;
                padding-top: calc(87.81% / 2);
                position: relative;
                opacity: 0;

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    flex: 0 0 50%;
                    width: 50%;
                    padding-top: calc(50% / 2); }
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    padding-top: 65%;
                    flex: 0 0 100%;
                    width: 100%; } }
            .text {
                align-content: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                flex: 0 0 40%;
                width: 50%;
                max-width: 950px;
                opacity: 0;

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    flex: 0 0 100%;
                    width: 100%;
                    max-width: 100%; }

                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    margin-top: 3.75 * $base-width; } } } } }
