.block-tastefinder {
    padding: 18.75*$base-width 0;
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding: 10*$base-width 0 12.5*$base-width; }
    .wrapper {
        max-width: $boxed;
        margin: 0 auto;
        padding: 0 2.5*$base-width;
        position: relative;
        .headline-wrapper {
            max-width: 900px;
            margin: 0 auto;
            text-align: center;
            padding-bottom: 12.5*$base-width;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding-bottom: 10*$base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding-bottom: 3.75*$base-width; }
            h2, .h2 {
                text-transform: uppercase;
                line-height: 0.8;
                margin-bottom: 0;
                font-size: 11rem;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    font-size: 8rem;
                    margin-bottom: 4.375 * $base-width; }
                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    font-size: 6rem;
                    margin-bottom: 3.75 * $base-width; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    line-height: 1; } } }

        #tastefinder {
            text-align: center;

            .count-nav {
                text-shadow: unset;
                margin-bottom: 2rem; }

            .step-wrap {
                position: relative;
                max-width: 770px;
                margin: 0 auto;
                .left-party {
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    top: -60px;
                    @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                        max-width: 45%; }
                    svg {
                        max-width: 95%;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 60%; }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            max-width: 50%; } } }

                .right-party {
                    opacity: 0;
                    position: absolute;
                    right: 0;
                    top: -60px;
                    @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                        max-width: 45%; }
                    svg {
                        max-width: 95%;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 60%; }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            max-width: 50%; } } }

                &.final_step {
                    .left-party, .right-party {
                        opacity: 1; } } }
            .tastes,
            .spirits {
                .form-group {
                    max-width: 1364px;
                    margin: 0 auto;
                    margin-top: 8.75*$base-width;
                    padding: 0 2.5*$base-width;
                    display: flex;
                    justify-content: space-evenly;
                    position: relative;
                    flex-wrap: wrap;
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        justify-content: flex-start;
                        padding: 2.5*$base-width; }

                    &:before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: -50%;
                        transform: translate(0, 50%);
                        z-index: -1;
                        background-image: linear-gradient(to right, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
                        background-size: 20px 1px;
                        background-repeat: repeat-x;
                        left: 0; }

                    &:after {
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: -50%;
                        transform: translate(0, 50%);
                        z-index: -1;
                        background-image: linear-gradient(to right, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
                        background-size: 20px 1px;
                        background-repeat: repeat-x;
                        left: 0; } } }

            .tab-inner {
                position: relative;
                max-width: 1364px;
                margin: 0 auto;
                .resetbtn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        position: relative; } } }

            .checkbox {
                position: relative;
                width: calc(100% * (1/5));
                flex: 0 0 auto;
                padding: 8.75*$base-width 0;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    width: calc(100% * (1/3)); }
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    width: calc(100% *  (1/2));
                    padding: 5*$base-width 0; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    padding: 3.75*$base-width 0; }

                .svg-top, .svg-bottom {
                    opacity: 0;
                    position: absolute;
                    width: 100%; }

                .inner {
                    font-size: 2.5rem;
                    font-family: $font-default;
                    font-weight: 500;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        font-size: 2rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        font-size: 1.8rem;
                        justify-content: flex-start; }
                    label {
                        padding-left: 12px; }

                    input {
                        width: 23px;
                        height: 23px;
                        background-color: #fff;
                        border-radius: 50%;
                        vertical-align: middle;
                        border: unset;
                        appearance: none;
                        -webkit-appearance: none;
                        outline: none;
                        cursor: pointer;
                        position: relative;
                        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                            width: 18px;
                            height: 18px; }
                        &:after {
                            content: '';
                            background-image: url('/wp-content/themes/bergauf/blocks/tastefinder/form-steps/svg/check.svg');
                            position: absolute;
                            width: 28px;
                            height: 28px;
                            bottom: 0;
                            background-repeat: no-repeat;
                            margin-left: 2px;
                            opacity: 0;
                            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                width: 20px;
                                height: 20px; } }

                        &:checked {
                            background-color: $hover;
                            + label {
                                color: $hover; }
                            + .svg-top {
                                opacity: 0.22; }
                            + .svg-bottom {
                                opacity: 0.22; }
                            &:after {
                                opacity: 1; } } } }
                &:hover {
                    cursor: pointer;
                    transition: $transition;
                    .inner {
                        color: $hover;
                        cursor: pointer;
                        label {
                            cursor: pointer; }
                        transition: $transition;
                        input {
                            background-color: $hover;
                            transition: $transition;
                            &:after {
                                opacity: 1;
                                transition: $transition; } } }
                    .svg-top, .svg-bottom {
                        opacity: 0.22;
                        transition: $transition; } }


                &.active {
                    .inner {
                        color: $hover; }
                    .svg-top, .svg-bottom {
                        opacity: 0.22; } }

                &.inactive {
                    opacity: 0.2;
                    &:hover {
                        cursor: initial;
                        .inner {
                            color: $light;
                            input {
                                background-color: $light;
                                &:after {
                                    opacity: 0; } } }
                        .svg-top, .svg-bottom {
                            opacity: 0; } } }

                &.floraltaste {
                    .svg-top {
                        max-width: 250px;
                        transform: translate(15%, -115%);
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 150px;
                            transform: translate(80%, -115%); }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            transform: translate(50%, -115%); } }

                    .svg-bottom {
                        max-width: 300px;
                        transform: translate(2%, 40%);
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 180px;
                            transform: translate(60%, 20%); }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            transform: translate(35%, 20%); }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            display: none; } } }

                &.fruity-freshtaste {
                    .svg-top {
                        max-width: 250px;
                        transform: translate(10%, -120%);
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 150px;
                            transform: translate(80%, -120%); }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            transform: translate(45%, -110%); } }

                    .svg-bottom {
                        max-width: 230px;
                        transform: translate(18%, 40%);
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 160px;
                            transform: translate(75%, 30%); }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            transform: translate(45%, 20%); } } }

                &.spicytaste {
                    .svg-top {
                        width: 340px;
                        transform: translate(0, -130%);
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 225px;
                            width: 100%;
                            transform: translate(35%, -100%); }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            transform: translate(15%, -100%); } }


                    .svg-bottom {
                        width: 345px;
                        transform: translate(0, 25%);
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 205px;
                            width: 100%;
                            transform: translate(45%, 20%); }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            transform: translate(25%, 20%); } } }

                &.citrustaste {
                    .svg-top {
                        max-width: 250px;
                        transform: translate(15%, -120%);
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 140px;
                            transform: translate(80%,-110%); }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            transform: translate(65%, -110%); } }

                    .svg-bottom {
                        max-width: 250px;
                        transform: translate(15%, 20%);
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 180px;
                            transform: translate(55%, 5%); }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            max-width: 165px;
                            transform: translate(40%, 5%); } } }

                &.herbaltaste {
                    .svg-top {
                        max-width: 180px;
                        transform: translate(25%, -175%);
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 125px;
                            transform: translate(100%, -140%); }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            max-width: 105px;
                            transform: translate(85%, -105%); } }

                    .svg-bottom {
                        width: 375px;
                        transform: translate(0, -55%);
                        z-index: -1;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            max-width: 215px;
                            width: 100%;
                            transform: translate(52%, -50%); }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            transform: translate(26%, -50%); } } }

                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    .svg-top, .svg-bottom {
                        display: none; } } }

            .button-wrapper {
                margin-top: 8.75*$base-width;
                // @media only screen and (max-width: #{map-get($breakpoints, s)})
                //     margin-top: 5*$base-width
                .btn {
                    margin: 0 1.25*$base-width;
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        margin: 0.7rem; } } }

            // Spirits
            .spirits {
                position: relative;

                .bottle-one {
                    position: absolute;
                    opacity: 0.22;
                    height: 416px;
                    left: 0;
                    transform: translate(-105%, 0);
                    svg {
                        height: 100%;
                        width: 100%;
                        z-index: -1; }

                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        height: 300px;
                        transform: translate(-50%, 80%); }
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        height: 250px;
                        bottom: 0; } }

                .bottle-three {
                    position: absolute;
                    opacity: 0.22;
                    height: 360px;
                    top: 0;
                    right: 0;
                    z-index: -1;
                    transform: translate(90%, -15%);
                    svg {
                        height: 100%;
                        width: 100%; }

                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        height: 250px;
                        transform: translate(50%, 100%); }
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        height: 200px;
                        bottom: 0;
                        top: unset; }
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        height: 150px; } }

                .form-group {
                    position: relative;
                    padding: 5*$base-width 2.5*$base-width;
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        padding: 2.5*$base-width; }
                    .bottle-two {
                        position: absolute;
                        width: 100%;
                        left: 60px;
                        top: 0;
                        max-width: 90px;
                        opacity: 0.22;
                        z-index: -1;
                        transform: translate(0, -95%); }

                    .glas {
                        position: absolute;
                        width: 100%;
                        right: 30px;
                        top: 0;
                        opacity: 0.22;
                        max-width: 115px;
                        transform: translate(0, -91%);
                        z-index: -1; }

                    .checkbox {
                        padding: 3.75*$base-width 0; } } }


            // Result
            .result {
                .match-hl {
                    font-size: 4rem;
                    padding: 8.75*$base-width 0 10*$base-width;
                    position: relative;
                    &:before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        background-image: linear-gradient(to right, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
                        background-size: 20px 1px;
                        background-repeat: repeat-x; } }
                .result-bottles-images {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        flex-wrap: wrap; }
                    .productimage {
                        padding: 0 5*$base-width;
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            padding: 2.5*$base-width; }
                        img {
                            max-width: 90px;
                            opacity: 0.3;
                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                max-width: 80px; } }
                        &.active {
                            img {
                                opacity: 1;
                                filter: drop-shadow(0px 0px 10px rgba($yellow, 0.8)); } }
                        &:hover {
                            img {
                                opacity: 1;
                                filter: drop-shadow(0px 0px 10px rgba($yellow, 0.8)); } } } }

                .match {
                    max-width: 715px;
                    margin: 0 auto;
                    padding:  12.5*$base-width 0 10*$base-width;
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        padding: 5*$base-width 0 10*$base-width; }
                    .match-content {
                        text-align: left;
                        h2 {
                            color: $yellow; } }
                    .button-row {
                        display: flex;
                        padding-top: 3.75*$base-width;
                        a {
                            margin-right: 20px; }
                        // @media only screen and (max-width: #{map-get($breakpoints, s)})
 } }                        //     justify-content: center

                .recipe-idea {
                    max-width: 715px;
                    margin: 0 auto;
                    position: relative;
                    display: none;
                    &.active {
                        display: block; }
                    .recipe-inner {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 2.5*$base-width;
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            flex-direction: column;
                            align-items: flex-start; }
                        .headline-wrapper {
                            text-align: left;
                            padding-bottom: 5*$base-width;
                            h2 {
                                color: $yellow;
                                font-size: 8rem;
                                line-height: 1;
                                display: block;
                                font-weight: 400;
                                text-shadow: 3px 0 7px $yellow;
                                text-transform: uppercase;
                                margin-bottom: 0;
                                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                    font-size: 6rem;
                                    margin-bottom: 0; }
                                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                    font-size: 4.8rem; }
                                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                    font-size: 4rem;
                                    text-shadow: 0 0 5px $yellow; } }

                            .headline-tags.subline {
                                margin-bottom: 0;
                                text-transform: inherit;
                                font-weight: 400;
                                font-size: 2rem;
                                margin-top: 1.5rem;
                                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                    font-size: 1.5rem;
                                    margin-top: 10px; } } }

                        .image-wrapper {
                            padding-right: 8.75*$base-width;
                            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                padding-right: 6.25*$base-width; }
                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                padding-right: 0;
                                padding-bottom: 4*$base-width; }
                            img {
                                max-width: 165px;
                                width: 100%;
                                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                    max-width: 135px; }
                                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                    max-width: 100px; } } }

                        .ingredient {
                            display: flex;
                            padding-bottom: 10px;
                            p {
                                padding-bottom: 0;
                                padding-left: 10px; }
                            svg {
                                fill: #3DC3B1;
                                width: 25px; } } }

                    .owl-nav {
                        display: flex;
                        position: absolute;
                        z-index: 10;
                        width: 100%;
                        justify-content: space-between;
                        top: 45%;
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            position: relative;
                            justify-content: flex-start;
                            padding-top: 5*$base-width; }
                        button {
                            width: 50px;
                            height: 50px;
                            transition: all 0.3s ease-in-out;
                            border-radius: 50%;
                            border: 2px solid #fff;
                            position: absolute;
                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                position: relative; }
                            &.owl-prev {
                                left: -20%;
                                @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                                    left: -10%; }
                                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                    left: 0; }
                                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                    left: unset;
                                    margin-right: 5px; } }

                            &.owl-next {
                                right: -20%;
                                @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                                    right: -10%; }
                                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                    right: 0; }
                                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                    right: unset;
                                    margin-left: 5px; } }



                            .owl-carousel-arrow {
                                background-color: transparent;
                                display: flex;
                                width: 100%;
                                height: 100%;
                                justify-content: center;
                                align-items: center;
                                img {
                                    width: 8px;
                                    height: auto;
                                    transition: all 0.3s ease-in-out;
                                    background-color: transparent;
                                    border-radius: 50%; } } } } } } } } }
