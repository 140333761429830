.block-store-locator {
    padding: 27.5*$base-width 0 6.25*$base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        padding: 27.5*$base-width 0 0; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding: 18.75*$base-width 0 0; }

    .wrapper {
        max-width: $boxed + 30px;
        margin: 0 auto;
        // padding: 0 2.5*$base-width
        padding: 0;

        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            padding: 0 2.5*$base-width; }

        .hero {
            position: relative;
            .info-box {
                background: rgba(248, 221, 33, 0.2);
                max-width: fit-content;
                margin: 0 auto;
                margin-bottom: 12.5*$base-width;
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    margin-bottom: 7.5*$base-width; }
                .subline {
                    padding: 3.5*$base-width 5*$base-width;
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        padding: 3.5*$base-width; } } }

            .headline-wrapper {
                max-width: 930px;
                margin: 0 auto;
                text-align: center;
                padding-bottom: 12.5*$base-width;

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    padding-bottom: 3 * $base-width; }

                h1, .h1 {
                    text-transform: uppercase;
                    line-height: 1;
                    margin-bottom: 0;
                    font-size: 11rem;
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        font-size: 9rem;
                        margin-bottom: 4.375 * $base-width; }
                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                        font-size: 7rem;
                        margin-bottom: 3.75 * $base-width; }
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        font-size: 6rem;
 }                        // text-shadow: unset
                    @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                        font-size: 5rem;
 } }                        // text-shadow: unset
                .preline {
                    padding-bottom: 0; } } }

        #kk_map-holder {
            display: flex;
            position: relative;
            justify-content: space-between;
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-top: 25*$base-width;
            // @media only screen and (max-width: #{map-get($breakpoints, xxxl)})
            //  // flex-wrap: wrap
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                flex-direction: column-reverse; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                padding-top: 22.5 * $base-width; }


            #pac-card {
                flex: 0 0 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;

                #pac-container {
                    padding: 5*$base-width 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    input[type="text"] {
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        color: #fff;
                        border-bottom: 2px solid #fff;
                        max-width: 815px;
                        font-weight: 700;
                        line-height: 1.4;
                        font-size: 2.5rem;
                        text-transform: uppercase;
                        font-family: $font-default, Tahoma, Verdana, sans-serif;
                        background-image: url(/wp-content/themes/bergauf/blocks/store-locator/assets/img/search.svg);
                        background-repeat: no-repeat;
                        background-position: bottom 5px right 1px;
                        background-size: 3rem;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            font-size: 2.4rem;
                            background-size: 2.5rem; }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            font-size: 2.2rem; }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            font-size: 2rem;
                            background-size: 2.2rem; }

                        &::placeholder {
                            color: $light;
                            font-weight: 700;
                            line-height: 1.43;
                            font-size: 2.5rem;
                            text-transform: uppercase;
                            font-family: $font-default, Tahoma, Verdana, sans-serif;
                            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                font-size: 2.4rem; }
                            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                font-size: 2.2rem; }
                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                font-size: 2rem; } }

                        &:focus-visible {
                            outline: 0; } } }

                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: -50%;
                    transform: translate(0, 50%);
                    z-index: -1;
                    background-image: linear-gradient(to right, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
                    background-size: 20px 1px;
                    background-repeat: repeat-x;
                    left: 0; }

                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    bottom: -50%;
                    transform: translate(0, 50%);
                    z-index: -1;
                    background-image: linear-gradient(to right, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
                    background-size: 20px 1px;
                    background-repeat: repeat-x;
                    left: 0; } }

            h2 {
                color: $yellow;
                padding-top: 15px;
                padding-left: 30px;
                margin-bottom: 15px;
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    font-size: 5rem; } }

            #map {
                flex: 0 0 58%;
                max-width: 58%;
                margin-left: 48px;
                min-height: 72.5rem;
                border-radius: 40px;
                // @media only screen and (max-width: #{map-get($breakpoints, xxxl)})
                //     flex: 0 0 58%
                //     max-width: 58%
                @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                    margin-left: 0; }

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin: 0; }
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    min-height: 60rem; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    min-height: 40rem; }


                &.full {
                    width: 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-left: 0; }

                button.gm-svpc {
                    display: none; }
                button.gm-control-active.gm-fullscreen-control {
                    display: none; }

                .gm-style-iw-c {
                    overflow: visible; }

                .cluster {
                    position: relative;
                    div {
                        position: absolute !important;
                        width: fit-content !important;
                        height: fit-content !important;
                        line-height: unset !important;
                        top: 40%;
                        left: 35%;
                        transform: translate(-50%, -50%); } }

                .gm-style-iw-tc {
                    display: none; }

                div[role="dialog"] {
                    display: block;
                    padding: 5*$base-width 10*$base-width 5*$base-width 5*$base-width !important;
                    background-color: #000;
                    color: #fff;
                    border-radius: 30px;
                    transform: translate3d(-45%,-65%,0);
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        padding: 4*$base-width 4*$base-width 2.8*$base-width 2.8*$base-width !important;
                        transform: translate3d(-50%,-60%,0);
                        max-width: 330px !important;

                        p {
                            line-height: 1.4; }

                        p.kk-maps-tooltip.products {
                            display: none; } }



                    &:focus, &:focus-visible {
                        outline: 0; }

                    p:last-child {
                        margin-bottom: 0; }

                    .headline {
                        font-size: 2.5rem;
                        text-transform: uppercase;
                        font-family: $font-default, Tahoma, Verdana, sans-serif;
                        color: $green;
                        font-weight: 700;
                        line-height: 1.6;
                        padding-bottom: 15px;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            font-size: 2.4rem;
                            line-height: 1.4; }
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            font-size: 2.2rem;
                            line-height: 1.2; }
                        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                            font-size: 2rem; } }

                    .kk-maps-tooltip {
                        .product-list {
                            display: flex;
                            gap: 5px;

                            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                flex-wrap: wrap; } }

                        img.kk-maps-tooltip.marker {
                            height: 47px;
                            width: auto;
                            bottom: -2rem;
                            position: absolute;
                            z-index: 999;
                            right: 46%; }

                        .product {
                            padding-bottom: 0;
                            img {
                                height: 70px; } } }

                    .address {
                        font-size: 1.8rem;
                        display: flex;
                        align-items: center;
                        img {
                            margin-right: 10px;
                            width: 19px;
                            height: 17px; }

                        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                            font-size: 1.6rem; } }

                    .gm-style-iw-d {
                        padding: 0;
                        overflow: hidden !important;
                        a.btn {
                            min-height: unset;
                            margin-top: 3rem;
                            padding-top: 1rem;
                            padding-bottom: 1rem;
                            border: 1px solid #fff;
                            border-color: #fff !important;
                            font-weight: 400;
                            text-transform: none;
                            letter-spacing: 0px;
                            line-height: 1.5;
                            font-size: 1.7rem;

                            &:hover, &:focus:hover {
                                background-color: #fff;
                                color: $secondary; }

                            &:active, &:focus {
                                background-color: $secondary; } } }

                    .gm-ui-hover-effect {
                        opacity: 1;
                        top: 25px !important;
                        right: 25px !important;

                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            top: 15px !important;
                            right: 15px !important; }

                        span {
                            background-color: #fff;
                            width: 18px !important;
                            height: 18px !important; }
                        &:hover {
                            span {
                                background-color: $green; } } } } }

            #panel {
                flex: 0 0 39%;
                width: 39%;
                position: relative;
                max-height: 72.5rem;
                transition: all 0.3s ease-in-out;
                // display: flex
                // flex-direction: column-reverse
                // @media only screen and (max-width: #{map-get($breakpoints, xxxl)})
                //     flex: 0 0 30%
                //     width: 30%
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    flex: 0 0 100%;
                    width: 100%;
                    padding-top: 6.25*$base-width;
                    overflow-y: hidden;
                    max-height: 100%; }

                &.closed {
                    display: none; }

                .list-wrapper {
                    max-height: 75%;
                    overflow-y: auto;
                    overflow-x: auto;
                    width: 100%;
                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                        max-height: 55rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        max-height: 50rem; }

                    &::-webkit-scrollbar {
                        width: 8px; }
                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 8px rgba(255,255,255,0.22);
                        border-radius: 10px; }
                    &::-webkit-scrollbar-thumb {
                        background-color: $green;
                        // background-image: url(/wp-content/themes/bergauf/blocks/store-locator/assets/img/scroller.svg)
 }                        // background-repeat: no-repeat

                    // &::-webkit-scrollbar
                    //     width: 8px
                    //     margin-left: 8px

                    // &::-webkit-scrollbar-track
                    //     -webkit-box-shadow: inset 0 0 8px rgba(255,255,255,0.22)
                    //     border-radius: 10px

                    // &::-webkit-scrollbar-thumb
                    //     background-image: url(/wp-content/themes/bergauf/blocks/store-locator/assets/img/scroller.svg)
                    //     width: 15px
 }                    //     background-repeat: no-repeat

                ul.panel-wrapper {
                    width: 100%;
                    padding-left: 0;
                    list-style-type: none;

                    li {
                        display: block;
                        padding: 3rem;
                        position: relative;

                        &:first-child {
                            &:before {
                                display: none; } }
                        &:last-child {
                            &::after {
                                display: none; } }

                        &:before {
                            content: '';
                            width: 88%;
                            height: 100%;
                            position: absolute;
                            top: -50%;
                            transform: translate(0, 50%);
                            z-index: -1;
                            background-image: linear-gradient(to right, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
                            background-size: 20px 1px;
                            background-repeat: repeat-x;
                            left: 30px; }

                        &:after {
                            content: '';
                            width: 88%;
                            height: 100%;
                            position: absolute;
                            bottom: -50%;
                            transform: translate(0, 50%);
                            z-index: -1;
                            background-image: linear-gradient(to right, #3F3F3F 0%, #3F3F3F 50%, transparent 50%);
                            background-size: 20px 1px;
                            background-repeat: repeat-x;
                            left: 30px; }



                        div {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                flex-direction: column; }

                            .product-info {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;

                                p {
                                    padding-right: 0;
                                    padding-left: 0;
                                    font-weight: 300;
                                    padding-bottom: 0; }

                                .place {
                                    display: flex;
                                    cursor: pointer;
                                    font-size: 2.5rem;
                                    text-transform: uppercase;
                                    font-family: $font-default, Tahoma, Verdana, sans-serif;
                                    font-weight: 700;
                                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                        font-size: 2.4rem; }
                                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                        font-size: 2.2rem; }
                                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                        font-size: 2rem; } }

                                .distanceText {
                                    margin-bottom: 0;
                                    cursor: pointer;
                                    font-size: 1.8rem;
                                    display: flex;
                                    align-items: center;
                                    text-transform: none;
                                    padding-top: 5px;
                                    font-weight: normal;
                                    img {
                                        margin-right: 10px;
                                        width: 19px;
                                        height: 17px; }

                                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                        font-size: 1.6rem; } }

                                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                    gap: 0; } } }


                        .product-list {
                            display: flex;
                            flex-direction: row;

                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                justify-content: flex-start;
                                gap: 10px;
                                margin-top: 35px; }

                            p {
                                padding-bottom: 0; }

                            p.plus-svg {
                                position: absolute;
                                right: 15px;
                                top: 48px;
                                img {
                                    opacity: 0.95; }

                                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                    display: none; } }

                            .product {
                                img {
                                    height: 70px;
                                    padding: 0 3.5px;
                                    width: auto; }

                                &:nth-of-type(n+5) {
                                    display: none; } } } }

                    li {
                        cursor: pointer;
                        &:hover, &:focus, &:active, &.active {
                            background-color: rgba($yellow ,0.07); } }

                    li + li {
                        padding-top: 3rem; } } } } } }
