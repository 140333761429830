a {
    color: $light;
    text-decoration: none;
    transition: $transition;

    &:hover {
        color: $hover; }

    &:focus {
        outline: 0; } }

strong,
.bold {
    font-weight: 700; }

p {
    padding-bottom: 2.5 * $base-width;
    font-size: 2rem;
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        font-size: 1.8rem; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 1.6rem;
        line-height: 1.8; } }

button {
    &:focus {
        outline: none; } }

h1,
.h1 {
    font-family: $headline-font, Tahoma, Verdana, sans-serif;
    font-size: 15rem;
    line-height: 1;
    display: block;
    color: $light;
    text-shadow: 3px 0 7px $yellow;
    margin-bottom: 5.625 * $base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        font-size: 10rem;
        margin-bottom: 4.375 * $base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        font-size: 6.5rem;
        margin-bottom: 3.75 * $base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 5rem;
        text-shadow: 0px 0 4px $yellow; } }

h2,
.h2 {
    font-family: $headline-font, Tahoma, Verdana, sans-serif;
    font-size: 8rem;
    line-height: 1;
    display: block;
    font-weight: 400;
    color: $light;
    text-shadow: 3px 0 7px $yellow;
    text-transform: uppercase;
    margin-bottom: 5.625 * $base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        font-size: 6rem;
        margin-bottom: 4.375 * $base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        font-size: 4.8rem;
        margin-bottom: 3.75 * $base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 4rem;
        text-shadow: 0 0 4px $yellow;
        margin-bottom: 3*$base-width; } }

h3,
.h3 {
    font-family: $headline-font, Tahoma, Verdana, sans-serif;
    line-height: 1;
    display: block;
    font-weight: 400;
    color: $light;
    text-shadow: 3px 0 7px $yellow;
    text-transform: uppercase;
    font-size: 6rem;
    margin-bottom: 5 * $base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        font-size: 4rem;
        margin-bottom: 3.75 * $base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        font-size: 3.5rem;
        margin-bottom: 3.75 * $base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 3rem;
        text-shadow: 0 0 3px $yellow;
        margin-bottom: 2.5*$base-width; } }


.subline {
    font-family: $font-default, Tahoma, Verdana, sans-serif;
    font-size: 2.8rem;
    color: $light;
    font-weight: 700;
    line-height: 1.43;
    text-transform: uppercase;
    margin-bottom: 3.75 * $base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        font-size: 2.4rem; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        font-size: 2.2rem;
        margin-bottom: 2.5 *$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 2rem; } }

.preline {
    font-family: $preline-font, Tahoma, Verdana, sans-serif;
    font-size: 6rem;
    padding-bottom: 10px;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        font-size: 4.8rem; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        font-size: 4rem; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 3rem;
        padding-bottom: 0; } }
